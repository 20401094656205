import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import appTheme from '../AppTheme';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const styles = theme => ({
    selector: {
        width: "100%",
        height: 40,
        marginRight: 10,
        marginBottom: 5,
        '& div': {
            height: '100%',
            '& div': {
                '& select': {
                    paddingRight: 32,
                    paddingLeft:14,
                    paddingTop:11,
                    paddingBottom:11
                }
            }
        }
    }
})

class Selector extends Component {
    state = {
        currentSelection: null,
        labelWidth: 0,
    };
    componentDidMount() {
        this.setState({
            labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
        });
    }

    handleChange = (event) => {
        const value = event.target.value
        this.props.onSelectionChange(value === "" ? null : value)
    }

    render() {
        const { classes, data, label, className, withAll = true } = this.props;
        return (
            <FormControl variant="outlined" className={className ? [classes.selector, className].join(" ") : classes.selector}>
                <InputLabel
                    shrink={true}
                    ref={ref => {
                        this.InputLabelRef = ref;
                    }}
                    htmlFor="outlined-selector-native-simple"
                >
                    {label}
                </InputLabel>
                <Select
                    native
                    value={this.props.value ? this.props.value : ""}
                    onChange={this.handleChange}
                    input={
                        <OutlinedInput
                            notched = {true}
                            name="selection"
                            labelWidth={this.state.labelWidth}
                            id="outlined-selector-native-simple"
                        />
                    }
                >
                    {withAll && <option key={"All"} value={""}>All</option>}
                    {data && data.map((s, index) => (
                        <option key={index} value={s}>{s}</option>
                    ))}
                </Select>
            </FormControl>
        )
    }
}

Selector.propTypes = {
    classes: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    onSelectionChange: PropTypes.func.isRequired
};

export default appTheme(withStyles(styles)(Selector));