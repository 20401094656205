import React, {Component} from 'react';
import PageLayout from './pages/Layout'
import appTheme from './AppTheme';
import {withStyles} from '@material-ui/core/styles';
import {HashRouter} from 'react-router-dom'

import { MuiPickersUtilsProvider } from 'material-ui-pickers';

import DateFnsUtils from '@date-io/date-fns';

const styles = theme => ({});

class App extends Component {

    render() {
        return (
            <HashRouter>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <PageLayout/>
                </MuiPickersUtilsProvider>
            </HashRouter>
        );
    }
}

export default appTheme(withStyles(styles)(App));

