import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Scrollbars} from 'react-custom-scrollbars';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import appTheme from '../AppTheme';
import {withRouter} from 'react-router-dom'
import SegmentedControl from '../components/SegmentedControl'
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import RestClient from '../rest/RestClient';
import Selector from '../components/Selector';
import Button from '@material-ui/core/Button';
import startOfWeek from 'date-fns/startOfWeek'
import startOfMonth from 'date-fns/startOfMonth'
import startOfDay from 'date-fns/startOfDay'
import _ from 'lodash'
import store from "../redux/store";
import {conf} from "../AppConf";

const styles = theme => ({
    page: {
        width: 'fit-content',
        margin: '25px auto',
        [theme.breakpoints.down('sm')]: {
            marginTop: 5,
            marginBottom: 5,
        }
    },
    pageContent: {
        borderRadius: "6px",
        backgroundColor: "#ffffff",
        boxShadow: "0 64px 64px 0 rgba(0,0,0,0.02), 0 32px 32px 0 rgba(0,0,0,0.02), 0 16px 16px 0 rgba(0,0,0,0.02), 0 8px 8px 0 rgba(0,0,0,0.02), 0 4px 4px 0 rgba(0,0,0,0.02), 0 2px 2px 0 rgba(0,0,0,0.02)",
        zIndex: 103
    },
    paperContainer: {
        marginTop: 0,
    },
    timeSelectionContainer: {},
    backgroundItem2: {
        width: 'calc(100% - 16px)',
        height: 6,
        backgroundColor: "#ffffff",
        boxShadow: '0 64px 64px 0 rgba(0,0,0,0.05), 0 32px 32px 0 rgba(0,0,0,0.05), 0 16px 16px 0 rgba(0,0,0,0.05), 0 8px 8px 0 rgba(0,0,0,0.05), 0 4px 4px 0 rgba(0,0,0,0.05), 0 2px 2px 0 rgba(0,0,0,0.05)',
        'border-bottom-left-radius': 6,
        'border-bottom-right-radius': 6,
        zIndex: 102
    },
    backgroundItem3: {
        width: 'calc(100% - 32px)',
        height: 6,
        backgroundColor: "#ffffff",
        boxShadow: "0 64px 64px 0 rgba(0,0,0,0.05), 0 32px 32px 0 rgba(0,0,0,0.05), 0 16px 16px 0 rgba(0,0,0,0.05), 0 8px 8px 0 rgba(0,0,0,0.05), 0 4px 4px 0 rgba(0,0,0,0.05), 0 2px 2px 0 rgba(0,0,0,0.05)",
        'border-bottom-left-radius': 6,
        'border-bottom-right-radius': 6,
        zIndex: 101
    },
    selectionContainer: {
        //margineTop: 25,
        //marginLeft: 16,
        //margineRight: 16,
        height: 248,
        backgroundImage: 'url(/app/images/leaderboard.png)',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '20% 20px'
    },
    paper: {
        backgroundColor: 'transparent',
        marginTop: '10px',
        padding: '10px 0',
        width: 482,
        height: 425,
        [theme.breakpoints.down('sm')]: {
            width: 332,
            height: 300,
        }
    },
    scroll: {},
    table: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 10,
            marginLeft: 0,
            marginRight: 0,
        },
    },
    tableCellNo: {
        width: 10,
        paddingLeft: 16,
        paddingRight: '16px !important',
        color: '#000000',
        border: 0
    },
    tableCell: {
        wordBreak: 'break-all',
        minWidth: 100,
        paddingLeft: 16,
        paddingRight: '16px !important',
        color: '#000000',
        border: 0
    },
    tableCellP: {
        wordBreak: 'break-word',
        minWidth: 106,
        paddingLeft: 8,
        paddingRight: '32px !important',
        color: '#000000',
        border: 0
    },
    tableRow: {
        '&:nth-child(even)': {
            backgroundColor: '#F8FAFE'
        },
        height: 35
    },
    filter: {
        width: 200,
        height: 40,
        marginRight: 0,
        marginBottom: 5,
        marginTop: 10,
        '& div': {
            height: '100%',
            '& input': {
                color: 'gray'
            },
            '& select': {
                color: 'gray'
            },
            '& svg': {
                color: 'gray'
            },
            '& fieldset': {
                borderColor: 'gray !important'
            },
        },
        '& label': {
            color: 'gray !important'
        },
        color: '#000000',
        [theme.breakpoints.down('sm')]: {
            width: 130,
        },
    },
    progress: {
        width: '450px',
        "margin": "12px auto auto auto",
        [theme.breakpoints.down('sm')]: {
            width: 300
        },
    },
    leaderboardLabel: {
        fontSize: 20,
        color: '#4A4A4A',
        fontWeight: '500'
    },
    teamLabel: {
        fontSize: 14,
        color: '#4A4A4A',
        fontWeight: '500'
    },
    rankedLabel: {
        fontSize: 24,
        color: '#4A4A4A',
        fontWeight: 200
    },
    usselectedButton: {
        color: '#4A4A4A',
        borderColor: '#4A4A4A',
        backgroundColor: ''
    },
    selectedButton: {
        background: "rgba(253, 174, 107, 1) linear-gradient(-220deg, rgba(253, 174, 107, 1) 0%, rgba(250,229,57, 1) 100%)",
        //backgroundImage: 'linear-gradient(83deg, #FDAE6B 0%, #FAE539 100%)',
        color: '#ffffff',
        //borderColor: '#ffffff',
        border: "1px solid rgba(151,151,151,0.1)",


    }
});

class UserPerformanceReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inProgress: false,
            err: null,
            userCategoriesWithValues: [],
            users: [],
            selectedCategory: undefined,
            selectedCategoryValue: undefined,
            selectedUser: undefined,
            data: null,
            dateSelection: 0,
        };
    }

    componentDidMount = () => {
        this.updateSelectorsData()
    }

    updateSelectorsData = () => {
        this.setState({inProgress: true, data: null});
        RestClient.getTeamPerfomansSelector(
            (teams) => {
                let sortedTeams = _.sortBy(teams, t => t.name);
                let teamsCategory = {
                    id: -1,
                    name: "Team",
                    values: _.map(sortedTeams, t => (
                            {
                                id: t.id,
                                name: t.name
                            }
                        )
                    )
                };
                RestClient.getUserCategoriesSelector((categoriesWithValues) => {
                        categoriesWithValues.push(teamsCategory)
                        let all = _.sortBy(categoriesWithValues, c => c.name)

                        this.setState({
                            inProgress: false,
                            userCategoriesWithValues: all,
                        });
                        this.fetchUsers()
                    },
                    (err) => {
                        this.setState({inProgress: false, err: err, data: []});
                    });
            },
            (err) => {
                this.setState({inProgress: false, err: err, data: []});
            })
    }

    fetchUsers = () => {
        const pId = this.props.ua.partner.id
        const categoryId = this.state.selectedCategory && this.state.selectedCategory.id;
        const categoryValueId = this.state.selectedCategoryValue && this.state.selectedCategoryValue.id;
        RestClient.getUsersByUserCategorySelector(pId, categoryId, categoryValueId,
            (users) => {
                this.setState({
                        users: users
                    }
                );
                this.updateTableData();
            },
            (err) => {
                this.setState({inProgress: false, err: err, data: []});
            }
        )
    }

    updateTableData = () => {
        const userId = this.state.selectedUser && this.state.selectedUser.id;
        let teamIds = [];
        let categoryValueIds = [];
        if (!this.state.selectedCategory) {
            console.log('ALL')
        } else if (this.state.selectedCategory.name === "Team") {
            if (this.state.selectedCategoryValue) {
                console.log('Single team')
                teamIds = [this.state.selectedCategoryValue.id];
                console.log(teamIds)
            } else {
                console.log("All teams")
                teamIds = _.map(this.state.selectedCategory.values, x => x.id);
                console.log(teamIds)
            }
        } else {
            if (this.state.selectedCategoryValue) {
                console.log('Single category value')
                categoryValueIds = [this.state.selectedCategoryValue.id];
                console.log(categoryValueIds)
            } else {
                console.log('Whole category')
                categoryValueIds = _.map(this.state.selectedCategory.values, x => x.id);
                console.log(categoryValueIds)
            }
        }

        this.setState({inProgress: true, data: null});
        RestClient.getUserPerformance(
            this.getUTCDate(this.getSelectionStartDate()),
            this.getUTCDate(new Date()),
            teamIds,
            categoryValueIds,
            userId,
            (data) => {
                this.setState({
                    inProgress: false,
                    data: data
                });
            },
            (err) => {
                this.setState({inProgress: false, err: err, data: null});
            }
        )

    }

    getSelectionStartDate = () => {
        let now = new Date();
        if (this.state.dateSelection === 0) {
            return startOfDay(this.addDays(now, -7))
        } else if (this.state.dateSelection === 1) {
            return startOfDay(this.addDays(now, -14))
        } else if (this.state.dateSelection === 2) {
            return startOfDay(this.addDays(now, -30))
        } else {
            return startOfDay(this.addDays(now, -90))
        }
    }

    addDays = (date, days) => {
        let d = new Date(date);
        let msPerDay = 24 * 60 * 60 * 1000;
        d.setTime(d.getTime() + msPerDay * days);
        return d
    }

    getUTCDate = (date) => {
        return +date //Math.round(+date/1000);
    }

    handleCategoryChange = (category) => {
        let selectedCategory = category ?
            _.find(this.state.userCategoriesWithValues, x => x.name === category)
            : undefined
        this.setState({
            selectedCategory: selectedCategory,
            selectedCategoryValue: undefined,
            selectedUser: undefined
        }, this.fetchUsers);
    };

    handleCategoryValueChange = (value) => {
        if (this.state.selectedCategory && value) {
            this.setState({
                selectedCategoryValue: _.find(this.state.selectedCategory.values, x => x.name === value),
                selectedUser: undefined
            }, this.fetchUsers)
        } else {
            this.setState({
                selectedCategoryValue: undefined,
                selectedUser: undefined
            }, this.fetchUsers);
        }
    };
    handleUserChange = (value) => {
        let selectedUser = value ?
            _.find(this.state.users, x => x.email === value)
            : undefined
        this.setState({
            selectedUser: selectedUser
        }, this.updateTableData)
    };

    handleDateSelection = (s) => {
        this.setState({dateSelection: s}, () => {
            this.updateTableData()
        })
    }

    render() {
        const {classes} = this.props;
        return (
            <div>
                <Grid
                    className={classes.page}
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <Grid
                        item
                        container
                        className={classes.pageContent}
                        direction={"column"}
                        justify={"center"}
                    >
                        <Grid
                            item
                            container
                            className={classes.selectionContainer}
                            direction={"column"}
                            justify={"flex-start"}
                            alignItems="center"
                        >
                            <Typography variant="h5" align="center" className={classes.leaderboardLabel}
                                        style={{marginTop: 35, marginBottom: 20}}>
                                User Performance
                            </Typography>
                            <Selector
                                label={"Category"}
                                data={this.state.userCategoriesWithValues.map(x => x.name)}
                                value={this.state.selectedCategory ? this.state.selectedCategory.name : null}
                                className={classes.filter}
                                onSelectionChange={this.handleCategoryChange}
                            />
                            <Selector
                                label={"Subcategory"}
                                style={(this.state.userCategoriesWithValues && this.state.userCategoriesWithValues.length > 0) ? {display: "block"} : {display: "none"}}
                                data={this.state.selectedCategory ? this.state.selectedCategory.values.map(x => x.name) : null}
                                value={this.state.selectedCategoryValue ? this.state.selectedCategoryValue.name : null}
                                className={classes.filter}
                                onSelectionChange={this.handleCategoryValueChange}
                            />
                            <Selector
                                label={"User"}
                                data={this.state.users.map(x => x.email)}
                                value={this.state.selectedUser ? this.state.selectedUser.email : null}
                                className={classes.filter}
                                onSelectionChange={this.handleUserChange}
                            />
                        </Grid>
                        <Grid
                            item
                            container
                            className={classes.timeSelectionContainer}
                            style={{padding: 4}}
                            justify={"center"}>
                            <Button variant={this.state.dateSelection === 0 ? "contained" : "outlined"} size="small"
                                    color="primary" style={{margin: 4}}
                                    onClick={() => this.handleDateSelection(0)}
                                    className={this.state.dateSelection === 0 ? classes.selectedButton : classes.usselectedButton}>Last
                                7 Days</Button>

                            <Button variant={this.state.dateSelection === 1 ? "contained" : "outlined"} size="small"
                                    color="primary" style={{margin: 4}}
                                    onClick={() => this.handleDateSelection(1)}
                                    className={this.state.dateSelection === 1 ? classes.selectedButton : classes.usselectedButton}>Last
                                14 Days</Button>

                            <Button variant={this.state.dateSelection === 2 ? "contained" : "outlined"} size="small"
                                    color="primary" style={{margin: 4}}
                                    onClick={() => this.handleDateSelection(2)}
                                    className={this.state.dateSelection === 2 ? classes.selectedButton : classes.usselectedButton}>Last
                                Month</Button>

                            <Button variant={this.state.dateSelection === 3 ? "contained" : "outlined"} size="small"
                                    color="primary" style={{margin: 4}}
                                    onClick={() => this.handleDateSelection(3)}
                                    className={this.state.dateSelection === 3 ? classes.selectedButton : classes.usselectedButton}>Last
                                Quarter</Button>
                        </Grid>
                        <Grid item className={classes.paperContainer}>
                            {this.state.inProgress && <LinearProgress className={classes.progress} variant="query"/>}
                            <div className={classes.paper}>
                                <Scrollbars
                                    className={classes.scroll}
                                    //autoHeight autoHeightMin="100%" autoHeightMax="100%"
                                    renderThumbVertical={({style, ...props}) =>
                                        <div {...props} style={{
                                            ...style,
                                            backgroundColor: '#ACC1E7',
                                            right: 10
                                        }}/>
                                    }
                                >
                                    <Table className={classes.table}>
                                        <TableHead style={{height: 20}}>
                                            {this.state.data && this.state.data.length > 0 &&
                                            <TableRow>
                                                <TableCell align="left" className={classes.tableCellNo} style={{
                                                    fontSize: 10,
                                                    paddingTop: 0,
                                                    paddingBottom: 0
                                                }}>#</TableCell>
                                                <TableCell align="left" className={classes.tableCell}
                                                           style={{fontSize: 10, paddingTop: 0, paddingBottom: 0}}>Content</TableCell>
                                                <TableCell align="right" className={classes.tableCellP} style={{
                                                    fontSize: 10,
                                                    paddingTop: 0,
                                                    paddingBottom: 0
                                                }}>Rating</TableCell>
                                            </TableRow>
                                            }
                                        </TableHead>
                                        <TableBody>
                                            {this.state.data && this.state.data.map((row, index) => (
                                                <TableRow key={index} className={classes.tableRow}>
                                                    <TableCell key={index + "a1"} align="left"
                                                               className={classes.tableCellNo}
                                                               style={{fontWeight: 300}}>{row.pos}</TableCell>
                                                    <TableCell key={index + "a2"} align="left"
                                                               className={classes.tableCell}
                                                               style={{fontWeight: 300}}>{row.name}</TableCell>
                                                    <TableCell key={index + "a3"} align="right"
                                                               className={classes.tableCellP}
                                                               style={{fontSize: 18}}>{row.value + '%'}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Scrollbars>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid className={classes.backgroundItem2} item/>
                    <Grid className={classes.backgroundItem3} item/>
                </Grid>
            </div>
        );
    }
}

UserPerformanceReport.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default appTheme(withStyles(styles)(withRouter(UserPerformanceReport)));