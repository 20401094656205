import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import appTheme from '../AppTheme';
import {withRouter} from 'react-router-dom'
import RestClient from '../rest/RestClient';
import Button from '@material-ui/core/Button';
import Selector from "../components/Selector";
import _ from "lodash";
import {Line} from 'react-chartjs-2';
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    page: {
        width: 'fit-content',
        margin: '25px auto',
        [theme.breakpoints.down('sm')]: {
            marginTop: 5,
            marginBottom: 5,
        }
    },
    pageContent: {
        padding: 16,
        borderRadius: "6px",
        backgroundColor: "#ffffff",
        boxShadow: "0 64px 64px 0 rgba(0,0,0,0.02), 0 32px 32px 0 rgba(0,0,0,0.02), 0 16px 16px 0 rgba(0,0,0,0.02), 0 8px 8px 0 rgba(0,0,0,0.02), 0 4px 4px 0 rgba(0,0,0,0.02), 0 2px 2px 0 rgba(0,0,0,0.02)",
        zIndex: 103,
        [theme.breakpoints.down('sm')]: {
            padding: 4,
        }
    },
    deselectedButton: {
        color: '#4A4A4A',
        borderColor: '#4A4A4A',
        backgroundColor: ''
    },
    selectedButton: {
        background: "rgba(253, 174, 107, 1) linear-gradient(-220deg, rgba(253, 174, 107, 1) 0%, rgba(250,229,57, 1) 100%)",
        color: '#ffffff',
        border: "1px solid rgba(151,151,151,0.1)",
    },
    filter: {
        width: 300,
        height: 40,
        marginRight: 10,
        marginBottom: 5,
        marginTop: 10,
        '& div': {
            height: '100%',
            '& input': {
                color: 'gray'
            },
            '& select': {
                color: 'gray'
            },
            '& svg': {
                color: 'gray'
            },
            '& fieldset': {
                borderColor: 'gray !important'
            },
        },
        '& label': {
            color: 'gray !important'
        },
        color: '#000000',
        [theme.breakpoints.down('sm')]: {
            width: 130,
        },
    },
    chart: {
        backgroundColor: 'transparent',
        padding: '10px 0',
        width: 650,
        height: 375,
        [theme.breakpoints.down('sm')]: {
            width: 332,
            height: 300,
        }
    },
    progress: {},
    sample: {
        alignSelf: 'stretch'
    }
});

class Impact extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inProgress: false,
            reportType: 0, // 0 - Partner, 1 - Deck, 2 - User, 3 -Card
            selectedUser: undefined,
            selectedDeck: undefined,
            selectedCard: undefined,
            users: [],
            decks: [],
            cards: [],
            data: [],
            err: null,
        };
    }

    componentDidMount = () => {
        this.updateData()
        this.updateSelectorsData()
    };

    handleReportSelection = (reportType) => {
        this.setState({
            reportType: reportType
        }, () => {
            this.updateData()
        });
    };

    handleUserChange = (user) => {
        this.setState({
            selectedUser: user ? _.find(this.state.users, x => x.name === user) : undefined
        }, () => {
            this.updateData()
        });
    };

    handleDeckChange = (deck) => {
        this.setState({
            selectedDeck: deck ? _.find(this.state.decks, x => x.name === deck) : undefined
        }, () => {
            if (this.state.selectedDeck) {
                RestClient.getCardsForSelector(this.state.selectedDeck.id,
                    (cards) => {
                        this.setState({
                            cards: _.sortBy(cards, [function (d) {
                                return d.name;
                            }])
                        });
                    },
                    (err) => {
                        this.setState({err: err, data: []});
                    });
            }
            if (this.state.reportType === 1) { // deck report
                this.updateData();
            } else if (this.state.reportType === 3) { // card report
                this.setState({
                    selectedCard: undefined
                }, () => {
                    this.updateData()
                });
            }
        });
    };

    handleCardChange = (card) => {
        this.setState({
            selectedCard: card ? _.find(this.state.cards, x => x.name === card) : undefined
        }, () => {
            this.updateData()
        });
    };

    updateSelectorsData = () => {
        RestClient.getRatingImprovementUsers(true,
            (users) => {
                this.setState({
                    users: _.sortBy(users, [function (u) {
                        return u.name;
                    }])
                });
            },
            (err) => {
                this.setState({err: err, data: []});
            });
        RestClient.getRatingImprovementDecks(
            (decks) => {
                this.setState({
                    decks: _.sortBy(decks, [function (d) {
                        return d.name;
                    }])
                });
            },
            (err) => {
                this.setState({err: err, data: []});
            });
    };

    updateData = () => {
        this.setState({inProgress: true, err: null, data: []});
        if (this.state.reportType === 0 && this.props.ua) {
            RestClient.getPartnerImpact(
                this.props.ua.partner.id,
                data => {
                    this.setState({data: data, inProgress: false})
                },
                err => this.setState({err: err, data: [], inProgress: false})
            )
        } else if (this.state.reportType === 1) { // deck
            let deckId = this.state.selectedDeck ? this.state.selectedDeck.id : undefined;
            RestClient.getDeckImpact(
                deckId,
                data => {
                    this.setState({data: data, inProgress: false})
                },
                err => this.setState({err: err, data: [], inProgress: false})
            )
        } else if (this.state.reportType === 2) {
            if (this.state.selectedUser) {
                RestClient.getUserImpact(
                    this.state.selectedUser.id,
                    data => {
                        this.setState({data: data, inProgress: false})
                    },
                    err => this.setState({err: err, data: [], inProgress: false})
                )
            } else {
                RestClient.getPartnerImpact(
                    this.props.ua.partner.id,
                    data => {
                        this.setState({data: data, inProgress: false})
                    },
                    err => this.setState({err: err, data: [], inProgress: false})
                )
            }
        } else if (this.state.reportType === 3) { // card
            let cardId = this.state.selectedCard ? this.state.selectedCard.id : undefined;
            if (this.state.selectedDeck) {
                RestClient.getCardImpact(
                    cardId,
                    data => {
                         this.setState({data: data, inProgress: false})
                    },
                    err => this.setState({err: err, data: [], inProgress: false})
                )
            } else {
                this.setState({data: [], inProgress: false})
            }
        } else {
            this.setState({inProgress: false});
        }
    };
    getChartData = () => ({
        labels: _.map(this.state.data, d => d.n),
        datasets: [{
            data: _.map(this.state.data, d => Math.round(d.accuracy * 100)),
            label: 'Accuracy',
            lineTension: 0,
            borderColor: "#0f4d92",
            fill: false
        }]
    });

    render() {
        const {classes} = this.props;
        return (
            <div>
                <Grid
                    className={classes.page}
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <Grid
                        item
                        container
                        className={classes.pageContent}
                        direction={"column"}
                        justify={"flex-start"}
                    >
                        <Grid
                            item
                            container
                            justify={"flex-start"}>
                            <Button variant={this.state.reportType === 0 ? "contained" : "outlined"} size="small"
                                    color="primary" style={{margin: 5}}
                                    onClick={() => this.handleReportSelection(0)}
                                    className={this.state.reportType === 0 ? classes.selectedButton : classes.deselectedButton}>Partner</Button>

                            <Button variant={this.state.reportType === 1 ? "contained" : "outlined"} size="small"
                                    color="primary" style={{margin: 5}}
                                    onClick={() => this.handleReportSelection(1)}
                                    className={this.state.reportType === 1 ? classes.selectedButton : classes.deselectedButton}>Deck</Button>

                            <Button variant={this.state.reportType === 2 ? "contained" : "outlined"} size="small"
                                    color="primary" style={{margin: 5}}
                                    onClick={() => this.handleReportSelection(2)}
                                    className={this.state.reportType === 2 ? classes.selectedButton : classes.deselectedButton}>User</Button>

                            <Button variant={this.state.reportType === 3 ? "contained" : "outlined"} size="small"
                                    color="primary" style={{margin: 5}}
                                    onClick={() => this.handleReportSelection(3)}
                                    className={this.state.reportType === 3 ? classes.selectedButton : classes.deselectedButton}>Card</Button>
                        </Grid>
                        <Grid
                            item
                            container
                            justify={"flex-start"}>
                            {this.state.reportType === 1 &&
                            <Selector
                                label={"Deck"}
                                data={this.state.decks.map(x => x.name)}
                                value={this.state.selectedDeck ? this.state.selectedDeck.name : null}
                                className={classes.filter}
                                onSelectionChange={this.handleDeckChange}
                            />
                            }
                            {this.state.reportType === 2 &&
                            <Selector
                                label={"User"}
                                data={this.state.users.map(x => x.name)}
                                value={this.state.selectedUser ? this.state.selectedUser.name : null}
                                className={classes.filter}
                                onSelectionChange={this.handleUserChange}
                            />
                            }
                            {this.state.reportType === 3 &&
                            <Selector
                                label={"Deck"}
                                data={this.state.decks.map(x => x.name)}
                                value={this.state.selectedDeck ? this.state.selectedDeck.name : null}
                                className={classes.filter}
                                onSelectionChange={this.handleDeckChange}
                            />
                            }
                            {this.state.reportType === 3 &&
                            <Selector
                                label={"Card"}
                                data={this.state.cards.map(x => x.name)}
                                value={this.state.selectedCard ? this.state.selectedCard.name : null}
                                className={classes.filter}
                                onSelectionChange={this.handleCardChange}
                            />
                            }
                        </Grid>

                        <div className={classes.chart}>
                            <Line data={this.getChartData()} options={chartOptions}/>
                        </div>
                        <Grid
                            item
                            container
                            className={classes.sample}
                            justify={"flex-end"}
                        >
                            <Typography style={{color: 'black', margin: '6px 0', display: 'none'}} variant="body1"
                                        align="right">
                                Sample size: {5} users
                            </Typography>
                        </Grid>
                        {this.state.inProgress && <LinearProgress className={classes.progress} variant="query"/>}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const chartOptions = {
    maintainAspectRatio: false,
    title: {
        display: false,
        text: ''
    },
    scales: {
        xAxes: [{
            scaleLabel: {
                display: true,
                labelString: 'Review Number'
            },
            offset: true,
            ticks: {
                callback: function (value, index, values) {
                    if (value === 1) {
                        return value + 'st';
                    } else if (value === 2) {
                        return value + 'nd';
                    } else if (value === 3) {
                        return value + 'rd';
                    }
                    return value;
                }
            }
        }],
        yAxes: [{
            scaleLabel: {
                display: true,
                labelString: '% Accuracy'
            },
            ticks: {
                beginAtZero: true,  // minimum value will be 0.
                suggestedMax: 100
            }
        }]
    },
    legend: {
        display: false,
    }
};


Impact.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default appTheme(withStyles(styles)(withRouter(Impact)));