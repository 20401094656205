import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Scrollbars } from 'react-custom-scrollbars';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import appTheme from '../AppTheme';
import {withRouter} from 'react-router-dom'
import SegmentedControl from '../components/SegmentedControl'
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import RestClient from '../rest/RestClient';
import Button from '@material-ui/core/Button';
import startOfWeek from 'date-fns/startOfWeek'
import startOfMonth from 'date-fns/startOfMonth'

const styles = theme => ({
    page: {
        width: 'fit-content',
        margin: '25px auto',
        [theme.breakpoints.down('sm')]: {
            marginTop: 5,
            marginBottom: 5,
        }
    },
    pageContent: {
        borderRadius: "6px",
        backgroundColor: "#ffffff",
        boxShadow: "0 64px 64px 0 rgba(0,0,0,0.02), 0 32px 32px 0 rgba(0,0,0,0.02), 0 16px 16px 0 rgba(0,0,0,0.02), 0 8px 8px 0 rgba(0,0,0,0.02), 0 4px 4px 0 rgba(0,0,0,0.02), 0 2px 2px 0 rgba(0,0,0,0.02)",
        zIndex: 103
    },
    backgroundItem2: {
        width:'calc(100% - 16px)',
        height: 6,
        backgroundColor: "#ffffff",
        boxShadow: '0 64px 64px 0 rgba(0,0,0,0.05), 0 32px 32px 0 rgba(0,0,0,0.05), 0 16px 16px 0 rgba(0,0,0,0.05), 0 8px 8px 0 rgba(0,0,0,0.05), 0 4px 4px 0 rgba(0,0,0,0.05), 0 2px 2px 0 rgba(0,0,0,0.05)',
        'border-bottom-left-radius': 6,
        'border-bottom-right-radius': 6,
        zIndex: 102
    },
    backgroundItem3: {
        width:'calc(100% - 32px)',
        height: 6,
        backgroundColor: "#ffffff",
        boxShadow: "0 64px 64px 0 rgba(0,0,0,0.05), 0 32px 32px 0 rgba(0,0,0,0.05), 0 16px 16px 0 rgba(0,0,0,0.05), 0 8px 8px 0 rgba(0,0,0,0.05), 0 4px 4px 0 rgba(0,0,0,0.05), 0 2px 2px 0 rgba(0,0,0,0.05)",
        'border-bottom-left-radius': 6,
        'border-bottom-right-radius': 6,
        zIndex: 101
    },
    selectionContainer: {
        //margineTop: 25,
        //marginLeft: 16,
        //margineRight: 16,
        height: 250,
        backgroundImage: 'url(/app/images/leaderboard.png)',
        backgroundSize:     'contain',
        backgroundRepeat:   'no-repeat',
        backgroundPosition: '50% 60px'
    },
    paper: {
        backgroundColor: 'transparent',
        padding: '10px 0',
        width: 482,
        height: 375,
        [theme.breakpoints.down('sm')]: {
            width: 332,
            height: 300,
        }
    },
    segmentSelection: {
        marginBottom: 20,
        borderColor: '#DCDCDC',
        '& label': {
            lineHeight: '38px',
            fontWeight: 600,
            fontSize: 14
        },
        '& .segmented-item-selected:before': {
            backgroundImage: 'linear-gradient(83deg, #FDAE6B 0%, #FAE539 100%)',
            //'box-shadow': '0 64px 64px 0 rgba(61,88,128,0.05), 0 32px 32px 0 rgba(61,88,128,0.05), 0 16px 16px 0 rgba(61,88,128,0.05), 0 8px 8px 0 rgba(61,88,128,0.05), 0 4px 4px 0 rgba(61,88,128,0.05), 0 2px 2px 0 rgba(61,88,128,0.05)',
        },
        '& .segmented-item': {
            backgroundColor: '#ffffff',
            color: 'rgba(74, 74, 74, 0.38)'
        },
    },
    scroll: {
    },
    table: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginRight: 0,
        },
    },
    tableCell: {
        wordBreak: 'break-all',
        minWidth: 150,
        paddingLeft:32,
        paddingRight:'32px !important',
        color: '#000000',
        border: 0
    },
    tableRow: {
        '&:nth-child(even)': {
            backgroundColor: '#F8FAFE'
        }
    },
    progress: {
        width:'450px',
        "margin": "16px auto",
        [theme.breakpoints.down('sm')]: {
            width: 300
        },
    },
    leaderboardLabel: {
        fontSize: 14,
        color: '#4A4A4A',
        fontWeight: '500'
    },
    rankedLabel: {
        fontSize: 24,
        color: '#4A4A4A',
        fontWeight: 200
    },
    usselectedButton: {
        color: '#4A4A4A',
        borderColor: '#4A4A4A',
        backgroundColor:''
    },
    selectedButton: {
        background: "rgba(253, 174, 107, 1) linear-gradient(-220deg, rgba(253, 174, 107, 1) 0%, rgba(250,229,57, 1) 100%)",
        color: '#ffffff',
        border: "1px solid rgba(151,151,151,0.1)",
    }
});

class Leaderboard extends Component {

    constructor(props) {
        super(props);
        //this.endDate = this.getPickerEndDate();
        //this.startDate = this.getPickerStartDate();
        this.rLimit = 25;
        this.state = {
            inProgress: false,
            err: null,
            isParticipation: true,
            data: [],
            dateSelection: 0
        };
    }
    componentDidMount = () => {
        this.updateTableData()
    }

    handleTableChange = (selection) => {
        this.setState({
            isParticipation: selection
        }, () => {
            this.updateTableData()
        });
    };

    updateTableData = () => {
        this.setState({ inProgress: true, data: [] });

        if (this.state.isParticipation) {
            RestClient.getParticipation(
                this.getUTCDate(this.getSelectionStartDate()),
                this.getUTCDate(new Date()),
                this.rLimit,
                (data) => {
                    this.setState({
                        inProgress: false,
                        data: data
                    });
                },
                (err) => {
                    this.setState({inProgress: false, err: err, data: []});
                }
            )
        } else {
            RestClient.getCorrectanswer(
                this.getUTCDate(this.getSelectionStartDate()),
                this.getUTCDate(new Date()),
                this.rLimit,
                (data) => {
                    this.setState({
                        inProgress: false,
                        data: data
                    });
                },
                (err) => {
                    this.setState({inProgress: false, err: err, data: []});
                }
            )
        }

    }

    getSelectionStartDate = () => {
        if (this.state.dateSelection === 0) {
            return startOfWeek(new Date())
        } else if (this.state.dateSelection === 1) {
            return startOfMonth(new Date())
        } else {
            return new Date(1483257956000)
        }
    }

    getUTCDate = (date) => {
        return +date //Math.round(+date/1000);
    }

    handleDateSelection = (s) => {
        this.setState({dateSelection: s}, () => {
            this.updateTableData()
        })
    }



    render() {
        const { classes } = this.props;
        const name = this.props.name ? this.props.name : 'key';
        return (
            <div key={name}>
            <Grid
                className={classes.page}
                container
                direction="column"
                justify="center"
                alignItems="center"
            >
                <Grid
                    item
                    container
                    className={classes.pageContent}
                    direction={"column"}
                    justify={"center"}
                >
                    <Grid
                        item
                        container
                        className={classes.selectionContainer}
                        direction={"column"}
                        justify={"center"}
                    >
                        <Typography variant="h5" align="center" className={classes.leaderboardLabel}>
                            Leaderboard
                        </Typography>
                        <Typography variant="h5" align="center" className={classes.rankedLabel}>
                            Users ranked by
                        </Typography>
                        <SegmentedControl
                            name={"segmentSelection_" + name}
                            className={classes.segmentSelection}
                            options={[
                                { label: "Participation", value: true, default: true },
                                { label: "Accuracy", value: false }
                            ]}
                            setValue={newValue => this.handleTableChange(newValue)}
                            style={{ width: 275, height: 40, color: '#ffffff' }}
                        />
                    </Grid>
                    <Grid item className={classes.paperContainer}>
                        {this.state.inProgress && <LinearProgress className={classes.progress} variant="query"/>}
                        <div className={classes.paper}>
                            <Scrollbars
                                className={classes.scroll}
                                //autoHeight autoHeightMin="100%" autoHeightMax="100%" 
                                renderThumbVertical={({ style, ...props }) =>
                                    <div {...props} style={{
                                        ...style,
                                        backgroundColor: '#ACC1E7',
                                        right:10
                                    }} />
                                }
                            >
                                <Table className={classes.table}>
                                    <TableHead style={{height: 20}}>
                                    {this.state.data.length > 0 &&
                                        <TableRow>
                                            <TableCell align="left" className={classes.tableCell} style={{fontSize: 10, paddingTop:0, paddingBottom:0}}>Email</TableCell>
                                            <TableCell align="right" className={classes.tableCell} style={{fontSize: 10, paddingTop:0, paddingBottom:0}}>
                                                {this.state.isParticipation ? "Participation" : "Correct Answer"}
                                            </TableCell>
                                        </TableRow>
                                    }
                                    </TableHead>
                                    <TableBody>
                                        {this.state.data.map(row => (
                                            <TableRow key={row.em} className={classes.tableRow}>
                                                <TableCell align="left" className={classes.tableCell} style={{fontWeight: 300}}>{row.em}</TableCell>
                                                <TableCell align="right" className={classes.tableCell} style={{fontSize: 18}}>{this.state.isParticipation ? row.c : (row.p + '%')}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Scrollbars>
                        </div>
                    </Grid>
                    <Grid 
                        item 
                        container 
                        className={classes.timeSelectionContainer}
                        style={{padding:5}}
                        justify={"flex-end"}>
                            <Button variant={this.state.dateSelection === 0 ? "contained" : "outlined"} size="small" color="primary" style={{margin:5}}
                             onClick={() => this.handleDateSelection(0)}
                             className={this.state.dateSelection === 0 ? classes.selectedButton : classes.usselectedButton}>This Week</Button>
                            <Button variant={this.state.dateSelection === 1 ? "contained" : "outlined"} size="small" color="primary" style={{margin:5}} 
                             onClick={() => this.handleDateSelection(1)}
                            className={this.state.dateSelection === 1 ? classes.selectedButton : classes.usselectedButton}>This Month</Button>
                            <Button variant={this.state.dateSelection === 2 ? "contained" : "outlined"} size="small" color="primary" style={{margin:5}}
                             onClick={() => this.handleDateSelection(2)} 
                            className={this.state.dateSelection === 2 ? classes.selectedButton : classes.usselectedButton}>All Time</Button>
                    </Grid>
                </Grid>
                <Grid className={classes.backgroundItem2} item />
                <Grid className={classes.backgroundItem3} item />
            </Grid>
            </div>
        );
    }
}
Leaderboard.propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired
};

export default appTheme(withStyles(styles)(withRouter(Leaderboard)));