import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import appTheme from '../AppTheme';
import {withRouter} from 'react-router-dom'
import Button from '@material-ui/core/Button';
import RestClient from '../rest/RestClient';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
    page: {
        overflowY: "auto",
        overflowX: "hidden",
        width: "100%",
        display: "inline-block",
        height: "calc(100vh - 132px)",
        // height: "100vh",
        // marginTop: "-132vw",
        // paddingTop: "132vw",
        boxSizing: "content-box",
        '@media (orientation: landscape) and (max-device-height: 460px)': {
            height: "100%",
            // height: "100vh",
            // marginTop: "0vw",
            // paddingTop: "0vw",
        },
    },

    settings: {
        // width: "360px",
        margin: "auto",
        minWidth: "320px",
        maxWidth: "400px",
        padding: "16px",
        height: "100%",
        minHeight: "460px",
    },
    textField: {
        width: 250,
    },
    button: {
        borderRadius: "8px",
        width: 150,
        marginTop: "16px",
        border: "2px solid #fff",
        '&:disabled': {
            border: "2px solid #ffffff4d",
        },
    },
    progress: {
        width: 300,
        marginTop: "16px"
    }
});

const passwordNotMatchErr = "Passwords do not match";

class UpdatePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inProgress: false,
            pws: undefined,
            newPws: undefined,
            newPws2: undefined,
        };
    }

    updPassword = () => {
        this.setState({inProgress: true});
        RestClient.updPasswordWithCurrent(this.props.ua.email, this.state.newPws, this.state.pws,
            () => {
                this.setState({inProgress: false, pws: "", newPws: "", newPws2: ""});

            },
            (err) => {
                this.setState({inProgress: false, err: err});
            }
        )
    };

    render() {
        const {classes} = this.props;
        return (
            <Grid container wrap={"nowrap"} className={classes.page} alignItems={"center"} justify={"center"} direction={"column"}>
                <Grid container wrap={"nowrap"} className={classes.settings} alignItems={"center"} justify={"center"} direction={"column"}>
                    <FormHelperText>Change password</FormHelperText>
                    <TextField
                        id="pws"
                        label="Current password"
                        placeholder="Current password"
                        className={classes.textField}
                        margin="normal"
                        required={true}
                        type={"password"}
                        disabled={this.state.inProgress}
                        onChange={(e) => {
                            this.setState({
                                pws: e.target.value,
                                err: null,
                            });
                        }}
                    />
                    <TextField
                        id="newPws"
                        label="New Password"
                        placeholder="New Password"
                        className={classes.textField}
                        type="password"
                        required={true}
                        margin="normal"
                        disabled={this.state.inProgress}
                        onChange={(e) => {
                            this.setState({
                                newPws: e.target.value,
                                err: null
                            });
                        }}
                    />
                    <TextField
                        id="newPws"
                        label="Retype New Password"
                        placeholder="Retype New Password"
                        className={classes.textField}
                        type="password"
                        required={true}
                        margin="normal"
                        disabled={this.state.inProgress}
                        onChange={(e) => {
                            this.setState({
                                newPws2: e.target.value,
                                err: this.state.newPws !== e.target.value ? passwordNotMatchErr : ""
                            });
                        }}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter' && (this.state.pws && this.state.newPws && this.state.newPws2 && this.state.newPws === this.state.newPws2)) {
                                this.updPassword();
                                e.preventDefault();
                            }
                        }}
                    />

                    {this.state.inProgress ? <LinearProgress className={classes.progress} variant="query"/> : <div></div>}
                    <FormHelperText error={this.state.err}>{this.state.err}</FormHelperText>

                    <Grid container alignItems={"flex-start"} justify={"space-around"} direction={"row"}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="default"
                            disabled={this.state.inProgress || !(this.state.pws && this.state.newPws && this.state.newPws2 && this.state.newPws === this.state.newPws2)}
                            onClick={() => this.updPassword()}
                            className={classes.button}>
                            Save
                        </Button>
                        <Button
                            variant="outlined"
                            size="large"
                            color="default"
                            disabled={this.state.inProgress}
                            onClick={() =>
                                this.props.history.push("/report/partner")
                            }
                            className={classes.button}>
                            Back
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

UpdatePassword.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default appTheme(withStyles(styles)(withRouter(UpdatePassword)));
