import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import appTheme from '../AppTheme';
import {withRouter} from 'react-router-dom'
import RestClient from '../rest/RestClient';
import Selector from "../components/Selector";
import _ from "lodash";
import {Line} from 'react-chartjs-2';
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    page: {
        width: 'fit-content',
        margin: '25px auto',
        [theme.breakpoints.down('sm')]: {
            marginTop: 5,
            marginBottom: 5,
        }
    },
    pageContent: {
        padding: 16,
        borderRadius: "6px",
        backgroundColor: "#ffffff",
        boxShadow: "0 64px 64px 0 rgba(0,0,0,0.02), 0 32px 32px 0 rgba(0,0,0,0.02), 0 16px 16px 0 rgba(0,0,0,0.02), 0 8px 8px 0 rgba(0,0,0,0.02), 0 4px 4px 0 rgba(0,0,0,0.02), 0 2px 2px 0 rgba(0,0,0,0.02)",
        zIndex: 103,
        [theme.breakpoints.down('sm')]: {
            padding: 4,
        }
    },
    filter: {
        width: 300,
        height: 40,
        marginRight: 10,
        marginBottom: 5,
        marginTop: 10,
        '& div': {
            height: '100%',
            '& input': {
                color: 'gray'
            },
            '& select': {
                color: 'gray'
            },
            '& svg': {
                color: 'gray'
            },
            '& fieldset': {
                borderColor: 'gray !important'
            },
        },
        '& label': {
            color: 'gray !important'
        },
        color: '#000000',
        [theme.breakpoints.down('sm')]: {
            width: 130,
        },
    },
    chart: {
        backgroundColor: 'transparent',
        padding: '10px 0',
        width: 650,
        height: 375,
        [theme.breakpoints.down('sm')]: {
            width: 332,
            height: 300,
        }
    },
    progress: {},
    sample: {
        alignSelf: 'stretch'
    }
});

class AccuracyReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inProgress: false,
            userCategoriesWithValues: [],
            selectedCategory: undefined,
            selectedCategoryValue: undefined,
            data: undefined,
            err: null,
        };
    }

    componentDidMount = () => {
        this.updateData()
        this.updateSelectorsData()
    };

    updateSelectorsData = () => {
        RestClient.getUserCategoriesSelector((categoriesWithValues) => {
                this.setState({
                    userCategoriesWithValues: categoriesWithValues
                });
            },
            (err) => {
                this.setState({err: err, data: []});
            });
    };

    fetchAllData = () => {
        RestClient.getCorrectAnswers(
            data => {
                this.setState({data: data, inProgress: false})
            },
            err => this.setState({err: err, data: undefined, inProgress: false})
        )
    };

    fetchDataByUserCategoryId = (userCategoryId) => {
        RestClient.getCorrectAnswersByUserCategoryId(
            userCategoryId,
            data => {
                this.setState({data: data, inProgress: false})
            },
            err => this.setState({err: err, data: undefined, inProgress: false})
        )
    };

    fetchDataByUserCategoryValueId = (userCategoryValueId) => {
        RestClient.getCorrectAnswersByUserCategoryValueId(
            userCategoryValueId,
            data => {
                this.setState({data: data, inProgress: false})
            },
            err => this.setState({err: err, data: undefined, inProgress: false})
        )
    };

    updateData = () => {
        if (this.state.selectedCategoryValue) {
            this.setState({inProgress: true, err: null, data: undefined},
                () => this.fetchDataByUserCategoryValueId(this.state.selectedCategoryValue.id))
        } else if (this.state.selectedCategory) {
            this.setState({inProgress: true, err: null, data: undefined},
                () => this.fetchDataByUserCategoryId(this.state.selectedCategory.id))
        } else {
            this.setState({inProgress: true, err: null, data: undefined},
                this.fetchAllData)
        }
    };

    getChartData = () => {
        return (this.state.data && this.state.data.rank && this.state.data.date) ?
            {
                labels: _.map(this.state.data.date, x => x),
                datasets: [
                    {
                        data: _.map(this.state.data.rank, x => Math.round(x * 100)),
                        label: 'Accuracy',
                        lineTension: 0,
                        borderColor: '#0f4d92',
                        fill: false
                    }
                ]
            }
            : {
                labels: [],
                datasets: []
            }
    };

    handleCategoryChange = (category) => {
        this.setState({
            selectedCategory: category ?
                _.find(this.state.userCategoriesWithValues, x => x.name === category)
                : undefined,
            selectedCategoryValue: undefined
        }, this.updateData);
    };

    handleCategoryValueChange = (value) => {
        if (this.state.selectedCategory && value) {
            this.setState({
                selectedCategoryValue: _.find(this.state.selectedCategory.values, x => x.name === value)
            }, this.updateData)
        } else {
            this.setState({
                selectedCategoryValue: undefined
            }, this.updateData);
        }
    };

    render() {
        const {classes} = this.props;
        return (
            <div>
                <Grid
                    className={classes.page}
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <Grid
                        item
                        container
                        justify={"flex-start"}>
                        <Selector
                            label={"Category"}
                            data={this.state.userCategoriesWithValues.map(x => x.name)}
                            value={this.state.selectedCategory ? this.state.selectedCategory.name : null}
                            className={classes.filter}
                            onSelectionChange={this.handleCategoryChange}
                        />
                        <Selector
                            label={"Category Value"}
                            data={this.state.selectedCategory ? this.state.selectedCategory.values.map(x => x.name) : null}
                            value={this.state.selectedCategoryValue ? this.state.selectedCategoryValue.name : null}
                            className={classes.filter}
                            onSelectionChange={this.handleCategoryValueChange}
                        />
                    </Grid>
                    <Grid
                        item
                        container
                        className={classes.pageContent}
                        direction={"column"}
                        justify={"flex-start"}
                    >
                        <div className={classes.chart}>
                            <Line data={this.getChartData()} options={chartOptions}/>
                        </div>
                        <Grid
                            item
                            container
                            className={classes.sample}
                            justify={"flex-end"}
                        >
                            <Typography style={{color: 'black', margin: '6px 0', display: 'none'}} variant="body1"
                                        align="right">
                                Sample size: {5} users
                            </Typography>
                        </Grid>
                        {this.state.inProgress && <LinearProgress className={classes.progress} variant="query"/>}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const
    chartOptions = {
        maintainAspectRatio: false,
        title: {
            display: false,
            text: ''
        },
        scales: {
            xAxes: [{
                type: 'time',
                time: {
                    unit: 'day',
                    tooltipFormat: 'D/MMM/YYYY',
                },
                offset: true
            }],
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: '% Accuracy'
                },
                ticks: {
                    beginAtZero: true,  // minimum value will be 0.
                    suggestedMax: 100
                }
            }]
        },
        tooltips: {
            callbacks: {
                label: function (tti, data) {
                    let label = data.datasets[tti.datasetIndex].label;
                    let value = tti.yLabel
                    return label + ': ' + value + '%'
                }
            }
        },
        legend: {
            display: false,
        }
    };


AccuracyReport
    .propTypes = {
    classes: PropTypes.object.isRequired,
};

export default appTheme(withStyles(styles)(withRouter(AccuracyReport)));