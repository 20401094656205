import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import appTheme from '../AppTheme';
import Paper from '@material-ui/core/Paper';
import {withRouter} from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import RestClient from '../rest/RestClient';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = theme => ({
    form: {
        "min-height": "180px",
        "min-width": "332px",
        "max-width": "332px",
        "padding": "16px"
    },
    textField: {
        width: 300,
    },
    button: {
        "border-radius": "8px",
        width: 300,
        "margin-top": "16px",
        border: "2px solid #fff",
        '&:disabled': {
            border: "2px solid #ffffff4d",
        },
    },
    progress: {
        width: 300,
        "margin-top": "16px"
    }
});

class ChangePassword extends Component {

    state = {
        inProgress: false,
        secret: undefined,
        pws: undefined,
        err: undefined,
    };

    parseSecret = () => {
        return window.location.href.split("?")[1];
    };

    updPassword = (event) => {
        this.setState({inProgress: true});
        RestClient.updPassword(this.parseSecret(), this.state.pws,
            () => {
                this.setState({inProgress: false});
                this.props.history.push("/study");
            },
            (err) => {
                this.setState({inProgress: false, err: err});
            }
        )
    };

    render() {
        const {classes} = this.props;
        return (
            <Paper className={classes.form}>
                <Grid container alignItems={"center"} justify={"center"} direction={"column"}>
                    <TextField
                        id="pws"
                        label="New password"
                        placeholder="New password"
                        className={classes.textField}
                        margin="normal"
                        autoFocus={true}
                        required={true}
                        type="password"
                        disabled={this.state.inProgress}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter' && (this.state.pws)) {
                                this.updPassword(e);
                                e.preventDefault();
                            }
                        }}
                        onChange={(e) => {
                            this.setState({
                                pws: e.target.value,
                                err: null,
                            });
                        }}
                    />

                    {this.state.inProgress ? <LinearProgress className={classes.progress} variant="query"/> : <div></div>}

                    <Button
                        variant="outlined"
                        size="large"
                        fullWidth={true}
                        color="default"
                        disabled={this.state.inProgress || !this.state.pws}
                        onClick={this.updPassword}
                        className={classes.button}>
                        Set new password
                    </Button>
                    <Grid container alignItems={"flex-start"} justify={"flex-start"} direction={"column"}>
                        <FormHelperText error={this.state.err}>{this.state.err}</FormHelperText>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

ChangePassword.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default appTheme(withStyles(styles)(withRouter(ChangePassword)));
