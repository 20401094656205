import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { InlineDatePicker } from "material-ui-pickers";
import Paper from '@material-ui/core/Paper';
import { Scrollbars } from 'react-custom-scrollbars';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import appTheme from '../AppTheme';
import {withRouter} from 'react-router-dom'
import SegmentedControl from '../components/SegmentedControl'
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import RestClient from '../rest/RestClient';
import Selector from '../components/Selector';
import _ from 'lodash'

const styles = theme => ({
    page: {
        width: 'fit-content',
        margin: '25px auto',
        [theme.breakpoints.down('sm')]: {
            marginTop: 5,
            marginBottom: 5,
        }
    },
    pageContent: {
        //width: '100%',
        //height: 490,
        borderRadius: "6px",
        backgroundColor: "#ffffff",
        boxShadow: "0 64px 64px 0 rgba(0,0,0,0.02), 0 32px 32px 0 rgba(0,0,0,0.02), 0 16px 16px 0 rgba(0,0,0,0.02), 0 8px 8px 0 rgba(0,0,0,0.02), 0 4px 4px 0 rgba(0,0,0,0.02), 0 2px 2px 0 rgba(0,0,0,0.02)",
        zIndex: 103
    },
    backgroundItem2: {
        width:'calc(100% - 16px)',
        height: 6,
        backgroundColor: "#ffffff",
        boxShadow: '0 64px 64px 0 rgba(0,0,0,0.05), 0 32px 32px 0 rgba(0,0,0,0.05), 0 16px 16px 0 rgba(0,0,0,0.05), 0 8px 8px 0 rgba(0,0,0,0.05), 0 4px 4px 0 rgba(0,0,0,0.05), 0 2px 2px 0 rgba(0,0,0,0.05)',
        'border-bottom-left-radius': 6,
        'border-bottom-right-radius': 6,
        zIndex: 102
    },
    backgroundItem3: {
        width:'calc(100% - 32px)',
        height: 6,
        backgroundColor: "#ffffff",
        boxShadow: "0 64px 64px 0 rgba(0,0,0,0.05), 0 32px 32px 0 rgba(0,0,0,0.05), 0 16px 16px 0 rgba(0,0,0,0.05), 0 8px 8px 0 rgba(0,0,0,0.05), 0 4px 4px 0 rgba(0,0,0,0.05), 0 2px 2px 0 rgba(0,0,0,0.05)",
        'border-bottom-left-radius': 6,
        'border-bottom-right-radius': 6,
        zIndex: 101
    },
    selectionContainer: {
        //margineTop: 25,
        //marginLeft: 16,
        //margineRight: 16,
        height: 200,
        backgroundImage: 'url(/app/images/leaderboard.png)',
        backgroundSize:     'cover',
        backgroundRepeat:   'no-repeat',
        //backgroundPosition: '50% 0'
    },
    paper: {
        backgroundColor: 'transparent',
        padding: '10px 0',
        width: 600,
        height: 325,
        [theme.breakpoints.down('sm')]: {
            width: 332,
            height: 300,
        }
    },
    filterDate: {
        width: 130,
        height: 40,
        marginRight: 10,
        marginBottom: 5,
        marginTop: 10,
        '& div': {
            height: '100%',
            '& input': {
                color: 'gray'
            },
            '& select': {
                color: 'gray'
            },
            '& svg': {
                color: 'gray'
            },
            '& fieldset': {
                borderColor: 'gray !important'
            },
        },
        '& label': {
            color: 'gray !important'
        },
        color: '#000000',
    },
    filter: {
        width: 210,
        height: 40,
        marginRight: 10,
        marginBottom: 5,
        marginTop: 10,
        '& div': {
            height: '100%',
            '& input': {
                color: 'gray'
            },
            '& select': {
                color: 'gray'
            },
            '& svg': {
                color: 'gray'
            },
            '& fieldset': {
                borderColor: 'gray !important'
            },
        },
        '& label': {
            color: 'gray !important'
        },
        color: '#000000',
        [theme.breakpoints.down('sm')]: {
            width: 130,
        },
    },
    scroll: {
    },
    table: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginRight: 0,
        },
    },
    tableCell: {
        wordBreak: 'break-all',
        minWidth: 150,
        paddingLeft:16,
        paddingRight:'16px !important',
        color: '#000000',
        border: 0
    },
    tableRow: {
        '&:nth-child(even)': {
            backgroundColor: '#F8FAFE'
        }
    },
    progress: {
        width:'450px',
        "margin": "16px auto",
        [theme.breakpoints.down('sm')]: {
            width: 300
        },
    },
    tableLabel: {
        fontSize: 14,
        color: '#4A4A4A',
        fontWeight: '500'
    },
});

class PercentChangeReport extends Component {

    constructor(props) {
        super(props);
        this.date = this.getPickerDate();
        this.rLimit = 9999;//Number.MAX_VALUE;
        this.state = {
            inProgress: false,
            err: null,
            data: [],
            decks: [],
            users: []
        };
    }
    componentDidMount = () => {
        this.updateTableData()
        this.updateSelectorsData()
    }


    handleDateChange = (date) => {
        this.date = date
        this.updateTableData()
    };

    handleUserChange = (user) => {
        this.user = user ? _.find(this.state.users, x => x.name === user) : null
        this.updateTableData()
    };
    handleDeckChange = (deck) => {
        this.deck = deck ? _.find(this.state.decks, x => x.name === deck) : null
        this.updateTableData()
    };


    updateTableData = () => {
        this.setState({ inProgress: true, data: [] });
        RestClient.getRatingImprovement(
            this.getUTCDate(this.date),
            this.rLimit,
            this.user ? this.user.id : null,
            this.deck ? this.deck.id : null,
            (data) => {
                this.setState({
                    inProgress: false,
                    data: data
                });
            },
            (err) => {
                this.setState({inProgress: false, err: err, data: []});
            })

    }

    updateSelectorsData = () => {
        this.setState({ users: [] });
        RestClient.getRatingImprovementUsers(false,
            (data) => {
            this.setState({
                users: _.sortBy(data, [function(o) { return o.name; }])
            });
        },
        (err) => {
            this.setState({err: err, data: []});
        })

        this.setState({ decks: [] });
        RestClient.getRatingImprovementDecks(
            (data) => {
                this.setState({
                    decks: _.sortBy(data, [function(o) { return o.name; }])
                });
            },
            (err) => {
                this.setState({err: err, data: []});
            })
    }

    getUTCDate = (date) => {
        return +date //Math.round(+date/1000);
    }

    getPickerDate = () => {
        return new Date()
    };




    render() {
        const { classes } = this.props;
        return (
            <div>
            <Grid
                className={classes.page}
                container
                direction="column"
                justify="center"
                alignItems="center"
            >
                <Grid
                    item
                    container
                    className={classes.pageContent}
                    direction={"column"}
                    justify={"center"}
                >
                        <Grid
                            item
                            className={classes.selectionContainer}
                        />
                        <Grid
                            item
                            container
                            direction={"column"}
                            justify={"flex-end"}
                        >
                        <Grid
                            item
                            container
                            direction={"row"}
                            xs={12}
                            justify={"flex-start"}
                            style = {{margin: '5px 16px'}}
                        >
                            <InlineDatePicker label="Date"
                                              className={classes.filterDate}
                                              variant="outlined"
                                              InputProps={{
                                                  //disableUnderline: true,
                                              }}
                                              disableFuture value={this.date}
                                              onChange={this.handleDateChange} />

                            <Selector
                                label={"User"}
                                data={this.state.users.map(x => x.name)}
                                value={this.user ? this.user.name : null}
                                className={classes.filter}
                                onSelectionChange={this.handleUserChange}
                            />
                            <Selector
                                label={"Deck"}
                                data={this.state.decks.map(x => x.name)}
                                value={this.deck ? this.deck.name : null}
                                className={classes.filter}
                                onSelectionChange={this.handleDeckChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid item className={classes.paperContainer}>
                        {this.state.inProgress && <LinearProgress className={classes.progress} variant="query"/>}
                        <div className={classes.paper}>
                            <Scrollbars
                                className={classes.scroll}
                                //autoHeight autoHeightMin="100%" autoHeightMax="100%" 
                                renderThumbVertical={({ style, ...props }) =>
                                    <div {...props} style={{
                                        ...style,
                                        backgroundColor: '#ACC1E7',
                                        right:10
                                    }} />
                                }
                            >
                                <Table className={classes.table}>
                                    <TableHead style={{height: 20}}>
                                    {this.state.data.length > 0 &&
                                        <TableRow style={{height:30}}>
                                            <TableCell align="left" className={classes.tableCell} style={{fontSize: 10, paddingTop:0, paddingBottom:0}}>User</TableCell>
                                            <TableCell align="left" className={classes.tableCell} style={{fontSize: 10, paddingTop:0, paddingBottom:0}}>Deck</TableCell>
                                            <TableCell align="left" className={classes.tableCell} style={{fontSize: 10, paddingTop:0, paddingBottom:0}}>Percent Change</TableCell>
                                        </TableRow>
                                    }
                                    </TableHead>
                                    <TableBody>
                                        {this.state.data.map((row, index) => (
                                            <TableRow key={index} className={classes.tableRow}>
                                                <TableCell align="left" className={classes.tableCell} style={{fontWeight: 300}}>{row.user}</TableCell>
                                                <TableCell align="left" className={classes.tableCell} style={{fontWeight: 300}}>{row.deck}</TableCell>
                                                <TableCell align="left" className={classes.tableCell} style={{fontWeight: 300}}>{(row.percent + '%')}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Scrollbars>
                        </div>
                    </Grid>
                </Grid>
                <Grid className={classes.backgroundItem2} item />
                <Grid className={classes.backgroundItem3} item />
            </Grid>
            </div>
        );
    }
}
PercentChangeReport.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default appTheme(withStyles(styles)(withRouter(PercentChangeReport)));