import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import appTheme from '../AppTheme';
import {withRouter} from 'react-router-dom'
import {conf} from '../AppConf';
import store from '../redux/store';

const styles = theme => ({
    link: {
        textDecoration: "none",
    }
});

class Report extends Component {
    getReportUrl(rId) {
        const ua = store.getState().user.ua;
        switch (rId) {
            case 1:
                //partner report
                return conf.url + "/mreport/d/ku8pUNfmz/partner-report?orgId=1&var-gid=" + ua.partner.gid + "&var-pId=" + ua.partner.id + "&theme=dark&kiosk";
            case 2:
                //user report
                return conf.url + "/mreport/d/W_wllHfiz/user-report?orgId=1&var-gid=" + ua.partner.gid + "&theme=dark&kiosk";
            case 3:
                //deck report
                return conf.url + "/mreport/d/FYNoXNfmk/deck-report?orgId=1&var-gid=" + ua.partner.gid + "&var-pId=" + ua.partner.id + "&theme=dark&kiosk";
            case 4:
                //users activity report
                return conf.url + "/mreport/d/STLLmB_ik/users-activity-report?orgId=1&var-gid=" + ua.partner.gid + "&theme=dark&kiosk";
            case 5:
                //stat by N-th viewing report
                return conf.url + "/mreport/d/4isUM1umk/stat-by-n-th-viewing-report?orgId=1&var-gId=" + ua.partner.gid + "&theme=dark&kiosk";
            case 6:
                //user - correct answer report
                return conf.url + "/mreport/d/xLmHawrmk/user-correct-answer-report?orgId=1&var-gId=" + ua.partner.gid + "&theme=dark&kiosk";
            case 7:
                //content - correct answer report
                return conf.url + "/mreport/d/NEr8yq9mz/content-correct-answer-report?orgId=1&var-gId=" + ua.partner.gid + "&theme=dark&kiosk";
            case 8:
                //time report
                return conf.url + "/mreport/d/Am-lyAjik/time-report?orgId=1&var-gId=" + ua.partner.gid + "&theme=dark&kiosk";
            case 9:
                //users accuracy by day
                return conf.url + "/mreport/d/abcfoobar/users-accuracy-by-day?orgId=1&var-gid=" + ua.partner.gid + "&theme=dark&kiosk";

            default:
                //partner report by default
                return conf.url + "/mreport/d/ku8pUNfmz/partner-report?orgId=1&var-gid=" + ua.partner.gid + "&var-pId=" + ua.partner.id + "&theme=dark&kiosk";
        }
    }

    render() {
        return (
            <iframe
                src={this.getReportUrl(this.props.rId)}
                style={{border: 0}}
                height="100%"
                width="100%"
                title="report">
            </iframe>
        );
    }
}

Report.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default appTheme(withStyles(styles)(withRouter(Report)));

