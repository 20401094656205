import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import appTheme from '../AppTheme';
import {withRouter} from 'react-router-dom'
import RestClient from '../rest/RestClient';
import Selector from "../components/Selector";
import _ from "lodash";
import {Pie} from 'react-chartjs-2';
import LinearProgress from "@material-ui/core/LinearProgress";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Scrollbars } from 'react-custom-scrollbars';
import { Typography } from '@material-ui/core';

const styles = theme => ({
    report: {
        backgroundColor: "#ffffff",
        width: 'fit-content',
        minWidth: 600,
        margin: '25px auto',
        marginTop: 5,
        marginBottom: 5,
        padding: 16,
        [theme.breakpoints.down('sm')]: {
            minWidth: 300,
        }
    },
    pageContent: {
    },
    filter: {
        height: 40,
        marginRight: 10,
        marginBottom: 5,
        marginTop: 10,
        '& div': {
            height: '100%',
            '& input': {
                color: '#000000'
            },
            '& select': {
                color: '#000000'
            },
            '& svg': {
                color: 'gray'
            },
            '& fieldset': {
                borderColor: 'gray !important'
            },
        },
        '& label': {
            color: 'gray !important'
        },
        color: '#000000',
        [theme.breakpoints.down('sm')]: {
        },
    },
    total: {
        marginTop: 10,
        color: '#000000'
    },
    chart: {
        backgroundColor: 'transparent',
        padding: '0',
        height: 300,
        [theme.breakpoints.down('sm')]: {
            height: 200,
        }
    },
    tableWrapper: {
        padding: '0',
        height: 300
    },
    table: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginRight: 0,
        },
    },
    tableCell: {
        wordBreak: 'break-all',
        minWidth: 175,
        paddingLeft:4,
        paddingRight:'4px !important',
        color: '#000000',
        border: 0,
        [theme.breakpoints.down('sm')]: {
            minWidth: 150,
        }
    },
    tableRow: {
        '&:nth-child(even)': {
            backgroundColor: '#F8FAFE'
        }
    },
    progress: {},
});

class SurveyReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inProgress: false,
            err: undefined,
            selectedSurvey: undefined,
            data: [],
        };
    }

    componentDidMount = () => {
        this.updateData()
    };

    updateData = () => {
        this.setState({err: undefined, inProgress: true})
        RestClient.getSurveys(
            data => {
                const selectedSurvey = data.length > 0 ? data[0].question : undefined;
                this.setState({data: data, inProgress: false, selectedSurvey: selectedSurvey})
            },
            err => this.setState({err: err, data: undefined, inProgress: false})
        )
    };

    getChartData = () => {
        const colors = [
            '#A50050',
            '#7FA9AE',
            '#007398',
            '#002F6C',
            '#FAC93D',
            '#ED8B00',
            '#B1B3B3',
            '#565A5C',
            '#db99b9',
            '#cbdcde',
            '#99c7d5',
            '#99abc4',
            '#fde9b1',
            '#f7d099',
            '#dfe0e0',
            '#bbbdbd',
            '#FAC93D',
            '#db99b9',
          ];
          
        const data = _.find(this.state.data, d => d.question === this.state.selectedSurvey);
        const answers = data ? data.answers : [];
        return (answers && answers.length > 0) ?
            {
                labels: _.map(answers, d => d.answer),
                datasets: [
                    {
                        data: _.map(answers, d => d.percent),
                        backgroundColor: colors,
                    }
                ]
            }
            : {
                labels: [],
                datasets: []
            }
    };
    getTableData = () => {
        const data = _.find(this.state.data, d => d.question === this.state.selectedSurvey);
        const answers = data ? data.answers : [];
        return _.flatten(
            _.map(answers, a => _.map(a.users, u => ({email: u, answer: a.answer})))
        )
    }
    getTotalAnswers = () => {
        const data = _.find(this.state.data, d => d.question === this.state.selectedSurvey);
        const answers =  data ? data.answers : [];
        return _.sum(_.map(answers, a => a.totalCount));
    }

    handleSurveyChange = (survey) => {
        this.setState({selectedSurvey: survey});
    };

    render() {
        const {classes} = this.props;
        const tableData = this.getTableData();
        const totalAnswers = this.getTotalAnswers();
        return (
            <Paper elevation={3} className={classes.report}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <Grid
                        item
                        container
                        justify={"flex-start"}>
                        <div>
                        <Selector
                            label={"Survey"}
                            withAll={false}
                            data={this.state.data.map(x => x.question)}
                            value={this.state.selectedSurvey}
                            className={classes.filter}
                            onSelectionChange={this.handleSurveyChange}
                        />
                        </div>
                    </Grid>
                    <Grid
                        item
                        container
                        className={classes.pageContent}
                        direction={"column"}
                        justify={"flex-start"}
                    >
                        <div className={classes.chart}>
                            <Pie data={this.getChartData()} options={chartOptions}/>
                        </div>
                    {totalAnswers !== undefined && <Typography align="center" variant="subtitle2" className={classes.total}>Total Responses: {totalAnswers}</Typography>}
                    </Grid>
                    {tableData.length > 0 && <Grid
                        item
                        container
                        className={classes.pageContent}
                        direction={"column"}
                        justify={"flex-start"}
                    >
                        <div className={classes.tableWrapper}>
                            <Scrollbars
                                autoHide
                                className={classes.scroll}
                                thumbMinSize={5}
                                renderThumbVertical={({ style, ...props }) =>
                                    <div {...props} style={{
                                        ...style,
                                        backgroundColor: '#99c7d5',
                                        right: -5
                                    }} />
                                }
                            >
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" className={classes.tableCell} style={{ fontSize: 14, paddingTop: 0, paddingBottom: 0 }}>User email</TableCell>
                                            <TableCell align="right" className={classes.tableCell} style={{ fontSize: 14, paddingTop: 0, paddingBottom: 0 }}>Answer</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableData.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="left" className={classes.tableCell} style={{ fontWeight: 400 }}>
                                                    {row.email}
                                                </TableCell>
                                                <TableCell align="right" className={classes.tableCell} style={{ fontSize: 14 }}>
                                                    {row.answer}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Scrollbars>
                        </div>
                    </Grid>}
                </Grid>
                {this.state.inProgress && <LinearProgress className={classes.progress} variant="query"/>}
            </Paper>
        );
    }
}

const
    chartOptions = {
        maintainAspectRatio: false,
        title: {
            display: true,
            text: 'Answers statistic'
        },
        tooltips: {
            callbacks: {
                label: function (tti, data) {
                    let label = data.datasets[tti.datasetIndex].data[tti.index];
                    return label + ' %'
                }
            }
        },
        legend: {
            display: true,
        }
    };


SurveyReport
    .propTypes = {
    classes: PropTypes.object.isRequired,
};

export default appTheme(withStyles(styles)(withRouter(SurveyReport)));