export default function report(state = {
    reportName: null
}, action) {
    switch (action.type) {
        case 'report-set':
            state.reportName = action.reportName;
            return state;

        default:
            return state;
    }
}
