export function setUser(ua) {
    return {
        type: 'user-set',
        ua: ua
    };
}

export function delUser() {
    return {
        type: 'user-del',
    };
}

