export const reportsList = [
    // {name: "Accuracy Report", path: "/accuracy"},
    {name: "Content Report", path: "/report/content-correct-answer"},
    {name: "Deck Report", path: "/report/deck"},
    // {name: "Leaderboard", path: "/leaderboard"},
    // {name: "Impact Report", path: "/impact"},
    {name: "Home", path: "/report/partner"},
    // {name: "Survey Report", path: "/survey"},
    // {name: "Percent Change Report", path: "/percentChangeReport"},
    // {name: "Stat by N-th Viewing Report", path: "/report/stat"},
    // {name: "Team Performance Report", path: "/teamPerformanceReport"},
    // {name: "User Performance Report", path: "/userPerformanceReport"},
    // {name: "Content Performance Report", path: "/contentPerformanceReport"},
    // {name: "Time Report", path: "/report/time"},
    {name: "Individual Report", path: "/report/user"},
    {name: "Team Report", path: "/report/user-correct-answer"},
    // {name: "Users Accuracy by Day", path: "/report/users-accuracy-by-day"},
    {name: "Usage Report", path: "/report/users-activity-report"}
];
