import React from 'react';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

const themeDefault = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#3023AE',
            dark: '#202b6b',
            light: "#ffffff",
        },
        background: {
            paper: '#000',
            default: '#000',
        }
    },
    srs: {
        // background: "#f7f8fa linear-gradient(-220deg, #3023AE 0%, #53A0FD 100%)",
        // background: "#f7f8fa",
        background: "#222426 linear-gradient(180deg,#222426 10px,#161719 100px);",
        header: {
            // background: "#f7f8fa linear-gradient(-220deg, #3023AE 0%, #53A0FD 100%)",
            // background: "#000",
        },
        footer: {
            // background: "#000",
            // background: "#424242",
        }
    }


});


var theme = undefined;

const THEME_LIGHT_BLUE = "LightBlue";
const THEME_PURPLE = "Purple";
const THEME_GREEN = "Green";
const THEME_DARK_BLUE = "DarkBlue";
export const THEME_DEFAULT = "Default";

export const themesList = [
    {key: THEME_DEFAULT, label: "Default"},
    {key: THEME_LIGHT_BLUE, label: "Light Blue"},
    {key: THEME_DARK_BLUE, label: "Dark Blue"},
    {key: THEME_PURPLE, label: "Purple"},
    {key: THEME_GREEN, label: "Green"},
];

export function setTheme(n) {
    localStorage.setItem("theme", THEME_DEFAULT);
    theme = themeDefault;
}

export function getTheme() {
    if (theme) {
        return theme;
    } else {
        const t = localStorage.getItem("theme");
        if (t) {
            setTheme(t);
            return getTheme();
        } else {
            return themeDefault;
        }
    }
}

function appTheme(Component) {
    return function (props) {
        return (
            <MuiThemeProvider theme={getTheme()}>
                <CssBaseline/>
                <Component {...props} />
            </MuiThemeProvider>
        );
    };
}

export default appTheme;
