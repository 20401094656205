import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import appTheme from '../AppTheme';
import Paper from '@material-ui/core/Paper';
import {Link, withRouter} from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import RestClient from '../rest/RestClient';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = theme => ({
    signIn: {
        "min-height": "250px",
        "min-width": "282px",
        "max-width": "282px",
        "padding": "16px"
    },
    textField: {
        width: 250,
    },
    button: {
        "border-radius": "8px",
        width: 250,
        "margin-top": "16px",
        border: "2px solid #fff",
        '&:disabled': {
            border: "2px solid #ffffff4d",
        },
    },
    progress: {
        width: 250,
        "margin-top": "16px"
    },
    link: {
        textDecoration: "none",
    }
});

class SignIn extends Component {
    constructor(props) {
        super(props);
        this.signIn = this.signIn.bind(this);
        // this.handleLogoutClick = this.handleLogoutClick.bind(this);
    }

    state = {
        inProgress: false,
        name: undefined,
        pws: undefined,
        isValid: false,
        err: undefined,
    };

    signIn = (event) => {
        this.setState({inProgress: true});
        RestClient.signIn(this.state.email, this.state.pws,
            () => {
                this.setState({inProgress: false});
                this.props.history.push("/report/partner");
            },
            (err) => {
                this.setState({inProgress: false, err: err});
            }
        )
    };

    render() {
        const {classes} = this.props;
        return (
            <Paper className={classes.signIn}>
                <Grid container alignItems={"center"} justify={"center"} direction={"column"}>
                    <TextField
                        id="email"
                        label="Email address"
                        placeholder="Email address"
                        className={classes.textField}
                        margin="normal"
                        autoFocus={true}
                        required={true}
                        type={"email"}
                        error={this.state.email !== "" && !this.state.isValid}
                        disabled={this.state.inProgress}
                        onChange={(e) => {
                            this.setState({
                                email: e.target.value,
                                err: null,
                                isValid: e.target.validity.valid,
                            });
                        }}
                    />
                    <TextField
                        id="pws"
                        label="Password"
                        className={classes.textField}
                        type="password"
                        required={true}
                        autoComplete="current-password"
                        margin="normal"
                        disabled={this.state.inProgress}
                        onChange={(e) => {
                            this.setState({
                                pws: e.target.value,
                                err: null
                            });
                        }}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter' && (this.state.email && this.state.pws && this.state.isValid)) {
                                this.signIn(e);
                                e.preventDefault();
                            }
                        }}
                    />

                    {this.state.inProgress ? <LinearProgress className={classes.progress} variant="query"/> : <div></div>}

                    <Button
                        variant="outlined"
                        size="large"
                        fullWidth={true}
                        color="default"
                        disabled={this.state.inProgress || !(this.state.email && this.state.pws && this.state.isValid)}
                        onClick={this.signIn}
                        className={classes.button}>
                        Sign In
                    </Button>
                    <Grid container alignItems={"flex-start"} justify={"flex-start"} direction={"column"}>
                        <FormHelperText error={this.state.err} style={{textAlign: 'center'}}>{this.state.err}</FormHelperText>
                        <Link to={"/resetPws"} className={classes.link}>
                            <FormHelperText>Forgot password?</FormHelperText>
                        </Link>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

SignIn.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default appTheme(withStyles(styles)(withRouter(SignIn)));


