import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import appTheme from '../AppTheme';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash'


const styles = theme => ({
    splitContainer: {
        height: '100%',
        width: '100%',
        //'border-collapse': 'collapse'
    },
    splitSingleItem: {
        alignSelf: 'stretch',
        flex: 1,
        margin: '8px 2px',
        padding: 8,
    },
    splitItem: {
        alignSelf: 'stretch',
        flex: 1,
        margin: '8px 2px',
        padding: 8,
        border: '2px solid rgba(255, 255, 255, 1)',
        borderRadius: 5,
        //backgroundColor: 'rgba(0, 0, 0, 0.2)',
        overflow: 'hidden'
    },
})
class SplitScreen extends Component {

    render() {
        const {classes} = this.props;
        const components = _.filter(this.props.children, x => x);
        const isMultiple = components.length !== 1;
        const isMobile = window.matchMedia("(max-width: 640px)").matches;
        return (
            <Grid container direction={isMobile ? "column" : "row"} className={classes.splitContainer} justify="center" alignItems='center'
                style={isMultiple ? {padding: '0 8px'} : null}
            >
                {_.map(components,(c,index) => {
                    return  (<Grid key={index} item className={isMultiple ? classes.splitItem : classes.splitSingleItem}>
                        {isMultiple && <Typography variant="h6" gutterBottom>Split Screen {index + 1}</Typography>}
                        {c}
                    </Grid>)
                })}
            </Grid>
        )
    }
}

SplitScreen.propTypes = {
};

export default appTheme(withStyles(styles)(withRouter(SplitScreen)));