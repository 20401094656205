import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import appTheme from '../AppTheme';
import {withRouter} from 'react-router-dom'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import * as reportActions from '../redux/actions/report';
import store from '../redux/store';
import {reportsList} from "../constants";

const styles = theme => ({
    link: {
        textDecoration: "none",
    },
    root: {
        width: '100%',
        maxWidth: 360,
        // backgroundColor: theme.palette.background.paper,
    },
});

class ReportList extends Component {

    handleOpenReport = (path) => {
        store.dispatch(reportActions.setReport(path));
        this.props.history.push(path);
    };

    state = {
        err: undefined
    };

    render() {
        const {classes, ua} = this.props;
        return (
            <div className={classes.root}>
                <List component="nav">
                    {reportsList.map((item, idx) =>
                        <ListItem key={idx}
                            onClick={() => {
                                this.handleOpenReport(item.path);
                            }}
                            button>
                            <ListItemText
                                primary={item.name}/>
                        </ListItem>
                    )}
                </List>
            </div>
        );
    }
}

ReportList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default appTheme(withStyles(styles)(withRouter(ReportList)));
