export const conf = {
    url:  window.API_URL || "https://mapp.blankslatetechnologies.com",
    baseDir:"",
    basePath:"/index.html#",
    rest: {
        baseUrl: "https://mapp.blankslatetechnologies.com/sreo/v0.1"
        // baseUrl: "http://pcalex/sreo/v0.1"
        // baseUrl: "http://localhost/sreo/v0.1"
    },
};

