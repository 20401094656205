import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import appTheme from '../AppTheme';
import {conf} from '../AppConf';
import Button from '@material-ui/core/Button';
import {Link, Route, Switch, withRouter} from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Divider from '@material-ui/core/Divider';
import store from '../redux/store';
import RestClient from '../rest/RestClient';
import SignIn from './SignIn';
import Report from "./Report";
import ReportList from './ReportList';
// import Settings from './Settings';
import UpdatePassword from './UpdatePassword';
import ChangePassword from './ChangePassword';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import * as reportActions from '../redux/actions/report';
import Leaderboard from './Leaderboard';
import PercentChangeReport from './PercentChangeReport';
import TeamPerformanceReport from './TeamPerformanceReport';
import UserPerformanceReport from './UserPerformanceReport';
import ContentPerformanceReport from './ContentPerformanceReport';
import SplitScreenReport from './SplitScreenReport'
import Tooltip from '@material-ui/core/Tooltip';
import DashboardIcon from '@material-ui/icons/Dashboard';
import {reportsList} from '../constants'
import Impact from "./Impact";
import AccuracyReport from "./AccuracyReport";
import SurveyReport from "./SurveyReport";

const styles = theme => ({
    root: {
        height: "100%",
        background: theme.srs.background,
        // color: theme.palette.text.primary,
    },
    header: {
        height: '64px',
        // '@media (orientation: landscape) and (max-device-height: 460px)': {
        //     display: 'none',
        // },
        // background: theme.srs.header.background,
        borderBottom: "solid #f9f9f9 1px",
    },
    footer: {
        height: '68px',
        '@media (orientation: landscape) and (max-device-height: 460px)': {
            display: 'none',
        },
        // background: theme.srs.footer.background,
        borderTop: "solid #f9f9f9 1px",
        // marginBottom: "-68px",
    },
    content: {
        height: '100%',
        //height: "calc(100vh - 64px)",
        // '@media (orientation: landscape) and (max-device-height: 460px)': {
        //     height: "100%",
        // },
        overflow: "auto"
    },
    button: {
        // color: theme.palette.text.primary,
    },
    logo: {
        maxWidth: "180px",
        paddingRight: "54px",
        maxHeight: "56px"
    },
    main_logo: {
        paddingLeft: "16px",
        color: "#fff",
        fontFamily: "orpheus_pro",
        fontSize: "32px",
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    }
});

class PageLayout extends Component {
    constructor(props) {
        super(props);
        RestClient.init(
            () => {
                store.dispatch(reportActions.setReport("/report/partner"));
                this.props.history.push("/report/partner");
            },
            (err) => {
                switch (this.props.location.pathname) {
                    case  '/pws':
                        break;
                    default:
                        this.props.history.push("/signin");
                        break;
                }
            },
            () => {
                this.props.history.push("/signin");
            }
        )
    }

    state = {
        anchorEl: null,
        isOnSplit: false,
    };

    openMenu = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    signOut = () => {
        this.setState({anchorEl: null});
        RestClient.signOut(
            () => {
                this.props.history.push("/signin");
            }
        )
    };

    handleMenuItemClick = (path) => {
        this.setState({anchorEl: null});
        this.props.history.push(path);
    };

    handleOpenReport = event => {
        store.dispatch(reportActions.setReport(event.target.value));
        this.props.history.push(event.target.value)
    };

    render() {
        const {anchorEl, isOnSplit} = this.state;
        const {classes} = this.props;
        const ua = store.getState().user.ua;
        return (
            <Grid container wrap={"nowrap"} className={classes.root} direction={"column"}>
                <Grid container className={classes.header} alignItems={"center"} justify={"center"}>
                    <Hidden xsDown>
                        <Grid item md={4}>
                            <a tabIndex="-1" target="blank" href="https://blankslatetechnologies.com/" style={{ textDecoration: "none"}}>
                                <span className={classes.main_logo}>/ Blank Slate</span>
                            </a>
                        </Grid>
                    </Hidden>
                    <Grid item md={4}>
                        {
                            (ua) ?
                                <FormControl className={classes.formControl}>
                                    <NativeSelect
                                        value={store.getState().report.reportName}
                                        onChange={this.handleOpenReport}
                                        name="report"
                                        className={classes.selectEmpty}
                                    >
                                        <option value="/reports">Select report</option>
                                        {reportsList.map((item, idx) =>
                                            <option key={idx} value={item.path}>{item.name}</option>
                                        )}
                                    </NativeSelect>

                                </FormControl>
                                : <div></div>
                        }
                        <Tooltip title={isOnSplit ? "Disable Split Screen" : "Enable Split Screen"}
                                 style={{position: 'relative', top: 10}}
                        >
                            <IconButton aria-label="Split Screen"
                                //style= {{padding: '22px 12px 2px 12px',backgroundColor:'transparent'}}
                                        onClick={() => {
                                            this.setState({isOnSplit: !this.state.isOnSplit})
                                        }}>
                                <DashboardIcon/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item md={4}>
                        <Grid container justify={"flex-end"}>
                            {
                                (ua) ?
                                    <Grid container justify={"flex-end"}>
                                        <Hidden xsDown>
                                            <a tabIndex="-1" target="blank" href={ua.partner.u}>
                                                <img
                                                    className={classes.logo}
                                                    src={conf.url + "/sreo/v0.1/app/media/" + ua.partner.l}
                                                    alt="logo"
                                                />
                                            </a>
                                            <IconButton
                                                style={{"position": "absolute"}}
                                                aria-label="More"
                                                aria-owns={anchorEl ? 'user-menu' : null}
                                                aria-haspopup="true"
                                                onClick={this.openMenu}>
                                                <MoreVertIcon className={classes.button}/>
                                            </IconButton>
                                        </Hidden>
                                    </Grid>
                                    : <Button component={Link} to={"/signin"}>Sign in</Button>
                            }
                            <Menu
                                id="user-menu"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={() => {
                                    this.setState({anchorEl: null});
                                }}
                                PaperProps={{style: {width: 150}}}>

                                <MenuItem key="ReportItem" onClick={() => {
                                    this.handleMenuItemClick("/reports");
                                }}>Reports</MenuItem>
                                <Divider/>
                                {/*<MenuItem key="Preferences" onClick={() => {this.handleMenuItemClick("/settings");}}>Preferences</MenuItem>*/}
                                <MenuItem key="Password" onClick={() => {
                                    this.handleMenuItemClick("/updatePws");
                                }}>Password</MenuItem>
                                <Divider/>
                                <MenuItem key="Log Out" onClick={() => {
                                    this.signOut();
                                }}>Log Out</MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.content} justify={"center"} alignItems={"center"}
                      direction={"column"}>
                    <Switch>
                        <Route path="/" exact component={SignIn}/>
                        <Route path={"/signin"} component={SignIn}/>
                        <Route path={"/pws"} component={ChangePassword}/>
                        <Route path={"/updatePws"} component={() => <UpdatePassword ua={ua}/>}/>
                        <Route path={"/reports"} component={() => <ReportList ua={ua}/>}/>

                        <Route path={"/report/partner"} render={() =>
                            <SplitScreenReport>
                                <Report ua={ua} rId={1}/>
                                {isOnSplit && <Report ua={ua} rId={1}/>}
                            </SplitScreenReport>
                        }/>
                        <Route path={"/report/user"} render={() =>
                            <SplitScreenReport>
                                <Report ua={ua} rId={2}/>
                                {isOnSplit && <Report ua={ua} rId={2}/>}
                            </SplitScreenReport>
                        }/>
                        <Route path={"/report/deck"} render={() =>
                            <SplitScreenReport>
                                <Report ua={ua} rId={3}/>
                                {isOnSplit && <Report ua={ua} rId={3}/>}
                            </SplitScreenReport>
                        }/>
                        <Route path={"/report/users-activity-report"} render={() =>
                            <SplitScreenReport>
                                <Report ua={ua} rId={4}/>
                                {isOnSplit && <Report ua={ua} rId={4}/>}
                            </SplitScreenReport>
                        }/>
                        <Route path={"/report/stat"} render={() =>
                            <SplitScreenReport>
                                <Report ua={ua} rId={5}/>
                                {isOnSplit && <Report ua={ua} rId={5}/>}
                            </SplitScreenReport>
                        }/>
                        <Route path={"/report/user-correct-answer"} render={() =>
                            <SplitScreenReport>
                                <Report ua={ua} rId={6}/>
                                {isOnSplit && <Report ua={ua} rId={6}/>}
                            </SplitScreenReport>
                        }/>
                        <Route path={"/accuracy"} render={() =>
                            <SplitScreenReport>
                                <AccuracyReport ua={ua}/>
                                {isOnSplit && <AccuracyReport ua={ua}/>}
                            </SplitScreenReport>
                        }/>
                        <Route path={"/survey"} render={() =>
                            <SplitScreenReport>
                                <SurveyReport ua={ua}/>
                                {isOnSplit && <SurveyReport ua={ua}/>}
                            </SplitScreenReport>
                        }/>
                        <Route path={"/report/content-correct-answer"} render={() =>
                            <SplitScreenReport>
                                <Report ua={ua} rId={7}/>
                                {isOnSplit && <Report ua={ua} rId={7}/>}
                            </SplitScreenReport>
                        }/>
                        <Route path={"/report/time"} render={() =>
                            <SplitScreenReport>
                                <Report ua={ua} rId={8}/>
                                {isOnSplit && <Report ua={ua} rId={8}/>}
                            </SplitScreenReport>
                        }/>
                        <Route path={"/leaderboard"} render={() =>
                            <SplitScreenReport>
                                <Leaderboard name="1" />
                                {isOnSplit && <Leaderboard name="2"/>}
                            </SplitScreenReport>
                        }/>
                        <Route path={"/percentChangeReport"} render={() =>
                            <SplitScreenReport>
                                <PercentChangeReport ua={ua}/>
                                {isOnSplit && <PercentChangeReport ua={ua}/>}
                            </SplitScreenReport>
                        }/>
                        <Route path={"/teamPerformanceReport"} render={() =>
                            <SplitScreenReport>
                                <TeamPerformanceReport ua={ua}/>
                                {isOnSplit && <TeamPerformanceReport ua={ua}/>}
                            </SplitScreenReport>
                        }/>
                        {/*<Route path={"/userPerformanceReport"} render={() =>*/}
                        {/*    <SplitScreenReport>*/}
                        {/*        <UserPerformanceReport ua={ua}/>*/}
                        {/*        {isOnSplit && <UserPerformanceReport ua={ua}/>}*/}
                        {/*    </SplitScreenReport>*/}
                        {/*}/>*/}
                        {/*<Route path={"/contentPerformanceReport"} render={() =>*/}
                        {/*    <SplitScreenReport>*/}
                        {/*        <ContentPerformanceReport ua={ua}/>*/}
                        {/*        {isOnSplit && <ContentPerformanceReport ua={ua}/>}*/}
                        {/*    </SplitScreenReport>*/}
                        {/*}/>*/}
                        <Route path={"/impact"} render={() =>
                            <SplitScreenReport>
                                <Impact ua={ua}/>
                                {isOnSplit && <Impact ua={ua}/>}
                            </SplitScreenReport>
                        }/>
                        <Route path={"/report/users-accuracy-by-day"} render={() =>
                            <SplitScreenReport>
                                <Report ua={ua} rId={9}/>
                                {isOnSplit && <Report ua={ua} rId={9}/>}
                            </SplitScreenReport>
                        }/>
                        <Route path={"/report/users-accuracy-by-day"} render={() =>
                            <SplitScreenReport>
                                <Report ua={ua} rId={9}/>
                                {isOnSplit && <Report ua={ua} rId={9}/>}
                            </SplitScreenReport>
                        }/>
                    </Switch>

                </Grid>
                {/*<Grid container className={classes.footer}>*/}
                {/*<Button href="https://blankslatetechnologies.com/">Blank Slate Technologies</Button>*/}
                {/*<Button href="mailto:communications@app.blankslatetechnologies.com">Help</Button>*/}
                {/*</Grid>*/}
            </Grid>
        );
    }
}

PageLayout.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default appTheme(withStyles(styles)(withRouter(PageLayout)));

