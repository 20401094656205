export default function user(state = {
    ua: null,
    partnerLogo: null
}, action) {
    switch (action.type) {
        case 'user-set':
            state.ua = action.ua;
            return state;

        case 'user-del':
            state.ua = null;
            state.partnerLogo = null;
            return state;

        default:
            return state;
    }
}