import store from '../redux/store';
import * as userActions from '../redux/actions/user';
import {conf} from '../AppConf';
import {setTheme, THEME_DEFAULT} from '../AppTheme';
import _ from 'lodash'

export default {

    redirectCallback: (() => {
    }),

    init: function (okCallback, errCallback, redirectCallback) {
        this.redirectCallback = redirectCallback;
        this.signIn(null, null, okCallback, errCallback);
    },

    processError: function (res, errCallback) {
        if (res && res.status === 403) {
            this.redirectCallback && this.redirectCallback();
        } else if (res && !res.ok) {
            res.text().then(
                (error) => {
                    errCallback && errCallback(this.cleanError(error));
                }
            );
        }
    },
    processSignInUpError: function (res, errCallback) {
        if (res && res.status === 403) {
            errCallback && errCallback("An Administrator has paused your account. Please contact them for more information. Have a great day!");
        } else if (res && !res.ok) {
            res.text().then(
                (error) => {
                    errCallback && errCallback(this.cleanError(error));
                }
            );
        }
    },
    processClientError: function (err, errCallback) {
        errCallback && errCallback(this.cleanError(err));
    },


    cleanError: function (errMsg) {
        if (errMsg && (errMsg + '').indexOf('<html>') > -1) {
            return "Something went wrong. Please try again later.";
        } else {
            return errMsg;
        }
    },

    signIn: function (email, pws, okCallback, errCallback) {
        const url = conf.rest.baseUrl + "/auth";
        const data = email && pws ? "email=" + encodeURIComponent(email) + "&pws=" + encodeURIComponent(pws) : "";
        fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            timeout: 15000,
            mode: 'same-origin', // no-cors, cors, *same-origin
            body: data
        }).then((res) => {
            if (res.ok) {
                return res.json().then(
                    (user) => {
                        if (user.typ === 2 || user.typ === 3 || user.typ === 9) {
                            store.dispatch(userActions.setUser(user));
                            setTheme(user.ui || user.partner.ui || THEME_DEFAULT);
                            okCallback && okCallback();
                        } else {
                            errCallback && errCallback("Access denied.");
                        }
                    }
                );
            } else {
                store.dispatch(userActions.delUser());
                this.processSignInUpError(res, errCallback);
            }
        }).catch((err) => {
            store.dispatch(userActions.delUser());
            this.processClientError(err, errCallback);
        });
    },

    signOut: function (okCallback, errCallback) {
        const url = conf.rest.baseUrl + "/auth";
        fetch(url, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
            },
            timeout: 15000,
            mode: 'same-origin'
        }).then((res) => {
            if (res.ok) {
                store.dispatch(userActions.delUser());
                okCallback && okCallback();
            } else {
                this.processError(res, errCallback);
            }
        }).catch((err) => {
            this.processClientError(err, errCallback);
        });
    },

    updateSettings: function (ua, okCallback, errCallback) {
        const url = conf.rest.baseUrl + "/app/user/settings";
        fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            timeout: 30000,
            body: JSON.stringify(ua)
        }).then((res) => {
            if (res.ok) {
                store.dispatch(userActions.setUser(ua));
                okCallback && okCallback();
            } else {
                this.processError(res, errCallback);
            }
        }).catch((err) => {
            this.processClientError(err, errCallback);
        });
    },
    resetPassword(email, okCallback, errCallback) {
        const url = conf.rest.baseUrl + "/auth/pws/reset";
        const data = email ? "email=" + encodeURIComponent(email) : "";
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            timeout: 30000,
            body: data
        }).then((res) => {
            if (res.ok) {
                okCallback && okCallback();
            } else {
                this.processError(res, errCallback);
            }
        }).catch((err) => {
            this.processClientError(err, errCallback);
        });
    },

    updPassword(secret, pws, okCallback, errCallback) {
        const url = conf.rest.baseUrl + "/auth/pws/upd";
        const data = "secret=" + encodeURIComponent(secret) + "&pws=" + encodeURIComponent(pws);
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            timeout: 30000,
            body: data
        }).then((res) => {
            if (res.ok) {
                okCallback && okCallback();
            } else {
                this.processError(res, errCallback);
            }
        }).catch((err) => {
            this.processClientError(err, errCallback);
        });
    },

    updPasswordWithCurrent(email, newPws, pws, okCallback, errCallback) {
        const url = conf.rest.baseUrl + "/auth/pws/upd";
        const data = "email=" + encodeURIComponent(email) + "&newPws=" + encodeURIComponent(newPws) + "&pws=" + encodeURIComponent(pws);
        fetch(url, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            timeout: 30000,
            body: data
        }).then((res) => {
            if (res.ok) {
                okCallback && okCallback();
            } else {
                this.processError(res, errCallback);
            }
        }).catch((err) => {
            this.processClientError(err, errCallback);
        });
    },

    getParticipation: function (startDt, endDt, limith, okCallback, errCallback) {
        const url = conf.rest.baseUrl
            + "/report/public/leaderboard/participation"
            + `/${startDt}/${endDt}/${limith}`;
        this.fetchGet(url, okCallback, errCallback);
    },
    getCorrectanswer: function (startDt, endDt, limith, okCallback, errCallback) {
        const url = conf.rest.baseUrl
            + "/report/public/leaderboard/correctanswer"
            + `/${startDt}/${endDt}/${limith}`;
        this.fetchGet(url, okCallback, errCallback);
    },

    getRatingImprovement: function (date, limith, userId, deckId, okCallback, errCallback) {
        var url = conf.rest.baseUrl
            + "/report/public/rating-improvement/"
            + `${date}/${limith}`;
        if (userId) {
            url += "?userId=" + userId
        }
        if (userId && deckId) {
            url += "&deckId=" + deckId
        } else if (deckId) {
            url += "?deckId=" + deckId
        }
        this.fetchGet(url, okCallback, errCallback);
    },
    getRatingImprovementUsers: function (onlyWithAnswers, okCallback, errCallback) {
        const url = conf.rest.baseUrl
            + "/report/public/selector/student?onlyWithAnswers=" + onlyWithAnswers
        this.fetchGet(url, okCallback, errCallback);
    },

    getPartnerImpact: function (partnerId, okCallback, errCallback) {
        this.getImpact("partner", partnerId, okCallback, errCallback);
    },

    getUserImpact: function (userId, okCallback, errCallback) {
        this.getImpact("user", userId, okCallback, errCallback);
    },

    getDeckImpact: function (deckId, okCallback, errCallback) {
        this.getImpact("deck", deckId, okCallback, errCallback);
    },

    getCardImpact: function (cardId, okCallback, errCallback) {
        this.getImpact("card", cardId, okCallback, errCallback);
    },

    getImpact: function (category, id, okCallback, errCallback) {
        let param = "";
        if (id) param = id;
        const url = conf.rest.baseUrl
            + "/report/public/impact/" + category + "/" + param;
        this.fetchGet(url, okCallback, errCallback);
    },

    getCardsForSelector: function (deckId, okCallback, errCallback) {
        const url = conf.rest.baseUrl
            + "/report/public/selector/deck/" + deckId + "/cards";
        this.fetchGet(url, okCallback, errCallback);
    },

    getRatingImprovementDecks: function (okCallback, errCallback) {
        const url = conf.rest.baseUrl
            + "/report/public/selector/deck"
        this.fetchGet(url, okCallback, errCallback);
    },

    getTeamPerfomansSelector: function (okCallback, errCallback) {
        const url = conf.rest.baseUrl
            + "/report/public/selector/team"
        this.fetchGet(url, okCallback, errCallback);
    },

    getUserCategoriesSelector: function (okCallback, errCallback) {
        const url = conf.rest.baseUrl
            + "/report/public/selector/user-categories"
        this.fetchGet(url, okCallback, errCallback);
    },

    getUsersByUserCategorySelector: function (pId, categoryId, categoryValueId, okCallback, errCallback) {
        const url = conf.rest.baseUrl + "/report/public/selector/users-by-categories"
        const data = {
            offset: 0,
            limit: 100000,
            pId: pId,
            categoryId: categoryId,
            categoryValueId: categoryValueId,
            sortBy: {
                field: 'email',
                ord: 0
            }
        }
        fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            timeout: 30000,
            body: JSON.stringify(data)
        }).then((res) => {
            if (res.ok) {
                return res.json().then(
                    (data) => {
                        console.log('data', data)
                        let res = data
                        if (categoryValueId) {
                            res = _.filter(data, (x) => x.userCategoryValue && x.userCategoryValue.id === categoryValueId)
                        } else if (categoryId) {
                            res = _.filter(data, (x) => x.userCategory && x.userCategory.id === categoryId)
                        }
                        console.log('res', res)
                        okCallback && okCallback(res);
                    });
            } else {
                this.processError(res, errCallback);
            }
        }).catch((err) => {
            this.processClientError(err, errCallback);
        });
    },


    getCorrectAnswers: function (okCallback, errCallback) {
        const url = conf.rest.baseUrl
            + "/report/public/correct-answers"
        this.fetchGet(url, okCallback, errCallback);
    },
    getSurveys: function (okCallback, errCallback) {
        const url = conf.rest.baseUrl
            + "/report/public/survey"
        this.fetchGet(url, okCallback, errCallback);
    },

    getCorrectAnswersByUserCategoryId: function (userCategoryId, okCallback, errCallback) {
        const url = conf.rest.baseUrl
            + "/report/public/correct-answers/user-category/" + userCategoryId
        this.fetchGet(url, okCallback, errCallback);
    },

    getCorrectAnswersByUserCategoryValueId: function (userCategoryValueId, okCallback, errCallback) {
        const url = conf.rest.baseUrl
            + "/report/public/correct-answers/user-category-value/" + userCategoryValueId
        this.fetchGet(url, okCallback, errCallback);
    },

    getPerformance: function (from, to, teamIds, categoryValueIds, okCallback, errCallback) {
        let url = conf.rest.baseUrl
            + "/report/public/team/performance/"
            + `${from}/${to}`;
        let queryParams = [];
        if (teamIds && teamIds.length > 0) {
            queryParams.push("teamId=" + _.join(teamIds))
        }
        if (categoryValueIds && categoryValueIds.length > 0) {
            queryParams.push("categoryValueId=" + _.join(categoryValueIds))
        }
        if (queryParams.length > 0) {
            let params = _.join(queryParams, "&")
            url += ("?" + params)
        }
        this.fetchGet(url, okCallback, errCallback);
    },

    getUserPerformance: function (from, to, teamIds, categoryValueIds, userId, okCallback, errCallback) {
        let url = conf.rest.baseUrl
            + "/report/public/performance/user/"
            + `${from}/${to}`;
        let queryParams = [];
        if (teamIds && teamIds.length > 0) {
            queryParams.push("teamId=" + _.join(teamIds))
        }
        if (categoryValueIds && categoryValueIds.length > 0) {
            queryParams.push("categoryValueId=" + _.join(categoryValueIds))
        }
        if (userId) {
            queryParams.push("userId=" + userId)
        }
        if (queryParams.length > 0) {
            let params = _.join(queryParams, "&")
            url += ("?" + params)
        }
        this.fetchGet(url, okCallback, errCallback);
    },

    getContentPerformance: function (from, to, cardIds, okCallback, errCallback) {
        let url = conf.rest.baseUrl
            + "/report/public/performance/content/"
            + `${from}/${to}`;
        let queryParams = [];
        if (cardIds && cardIds.length > 0) {
            queryParams.push("cardId=" + _.join(cardIds))
        }
        if (queryParams.length > 0) {
            let params = _.join(queryParams, "&")
            url += ("?" + params)
        }
        this.fetchGet(url, okCallback, errCallback);
    },

    fetchGet: function (url, okCallback, errCallback) {
        fetch(url, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
            },
            //mode: 'same-origin', // no-cors, cors, *same-origin
            timeout: 30000,
        }).then((res) => {
            if (res.ok) {
                return res.json().then(
                    (data) => {
                        okCallback && okCallback(data);
                    });
            } else {
                this.processError(res, errCallback);
            }
        }).catch((err) => {
            this.processClientError(err, errCallback);
        });
    },

}

